import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import TopContentCard from 'src/components/chartViews/topContent/TopContentCard';
import styles from 'src/stylesheets/chartViews/topContent/exportTopContentCard.scss';
import classnames from 'classnames';
import _chunk from 'lodash/chunk';
import objectHash from 'object-hash';

const ExportTopContentView = (props) => {
    const { results, numberFormat } = props;
    const chunks = _chunk(results, 4);
    return (
        <div>
            {
                chunks.map((chunk, chunkIndex) => {
                    const isLastChunk = chunkIndex === chunks.length - 1;
                    return (
                        <div className={classnames(styles.wrapper, { [styles.pageBreakAfter]: !isLastChunk })}>
                            {
                                chunk.map((result) => (
                                    <div key={`topContent-${objectHash(result)}`} className={classnames(styles.item)}>
                                        <TopContentCard
                                          pageName={result.pageName}
                                          content={result.content}
                                          profilePicture={result.profilePicture}
                                          thumbnail={result.thumbnail}
                                          link={result.link}
                                          kpis={result.kpis}
                                          engagementRate={result.engagementRate}
                                          platformType={result.platformType}
                                          data={result.data}
                                          numberFormat={numberFormat}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

ExportTopContentView.propTypes = {
    results: PropTypes.arrayOf(customPropTypes.data).isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired
};

export default ExportTopContentView;
