import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

export const adAccountUseCasesAuthUsers = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return accountAuthenticationEntities.adAccountUseCasesAuthUsers;
        }
        default:
            return state;
    }
};
