import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/filter.scss';
import ProfileSelectorLabel from 'src/components/profileSelector/ProfileSelectorLabel';
import ProfileSelectorButtonIcon from 'src/components/ProfileSelectorButtonIcon';
import FilterItem from './FilterItem';

const ProfileFilterSummary = (props) => {
    const {
        state,
        input,
        hasCustomBinding
    } = props;
    // input === profileIds, groupIds
    const icon = (
        <div className={styles.networkButtonWrapper}>
            <ProfileSelectorButtonIcon selectedProfilesOrGroups={input} disabled={false} />
        </div>
    );

    return (
        <FilterItem
          state={state}
          hasCustomBinding={hasCustomBinding}
          icon={icon}
          content={<ProfileSelectorLabel selectedProfilesOrGroups={input} />}
        />
    );
};

ProfileFilterSummary.propTypes = {
    state: PropTypes.oneOf(['fullyApplied', 'partiallyApplied', 'notApplied']).isRequired,
    input: customPropTypes.profileFilter.isRequired,
    hasCustomBinding: PropTypes.bool
};

ProfileFilterSummary.defaultProps = {
    hasCustomBinding: false
};

export default ProfileFilterSummary;
