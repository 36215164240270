import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';
import radarChart from './highcharts/configs/RadarChart';

const RadarChartView = highchartsChartViewFactory(radarChart);

RadarChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    context: PropTypes.string.isRequired,
    customColors: customPropTypes.customColors.isRequired,
    weekDefinition: PropTypes.oneOf(['SuSa', 'MoSu']).isRequired,
    isSampleMetric: PropTypes.bool
};

RadarChartView.defaultProps = {
    isSampleMetric: false
};

export default RadarChartView;
