import React from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import styles from 'src/stylesheets/chartViews/bigNumber/changeValue.scss';
import PropTypes from 'prop-types';
import { getRendererByType, percent } from 'src/components/chartViews/renderers';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';

const ChangeValue = (props) => {
    const {
        value, type, numberFormat, big, changePercentValue = null
    } = props;
    const renderer = getRendererByType(type);
    return (
        <div className={classnames(styles.wrapper, { [styles.big]: big })}>
            <IcomoonIcon className={classnames(styles.changeIcon, { [styles.green]: value >= 0, [styles.red]: value < 0 })} icon={value >= 0 ? 'chevron-big-up' : 'chevron-big-down'} />
            <div className={styles.value}>
                { (value > 0 ? '+' : '') + renderer(value, numberFormat) }
                { changePercentValue !== null && <span className={styles.changePercentValue}> ({(changePercentValue > 0 ? '+' : '') + percent(changePercentValue, numberFormat)})</span> }
            </div>
        </div>
    );
};

ChangeValue.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    changePercentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: customPropTypes.metaDataValueType.isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired,
    big: PropTypes.bool
};

ChangeValue.defaultProps = {
    big: false
};

export default ChangeValue;
