import { combineReducers } from 'redux';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

const visualizationsById = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA: {
            return payload.visualizations;
        }
        default:
            return state;
    }
};

const visualizationsId = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA: {
            return [...Object.keys(payload.visualizations)];
        }
        default:
            return state;
    }
};

export const visualizations = combineReducers({ byId: visualizationsById, allIds: visualizationsId });
