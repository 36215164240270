import React, { PureComponent } from 'react';
import _get from 'lodash/get';
import ExportMetricRequestContext from 'src/components/context/ExportMetricRequestContext';
import PropTypes from 'prop-types';
import ResultsWrapper from 'src/components/chartViews/fixedDataTable/ResultsWrapper';
import Table from 'src/components/chartViews/fixedDataTable/tableEngine/export/Table';

class ExportTable extends PureComponent {
    render() {
        const {
            tableConfig, settings, results, summaryLine
        } = this.props;

        const { columns } = tableConfig;
        const hideColumns = _get(settings, 'settingAdditional.hideColumns', tableConfig.hideColumns);
        const wrappedResults = new ResultsWrapper(results);
        return (
            <ExportMetricRequestContext.Consumer>
                {
                    (metricRequest) => {
                        const { parameters } = metricRequest;
                        return (
                            <Table
                              columnsSortBy={parameters.sortBy}
                              columnsSortDir={parameters.sortDir}
                              columns={columns}
                              hideColumns={hideColumns}
                              results={wrappedResults}
                              summaryLineResults={summaryLine}
                              summaryLineEnabled={_get(tableConfig, 'summaryLine.enabled', false)}
                            />
                        );
                    }
            }
            </ExportMetricRequestContext.Consumer>
        );
    }
}

ExportTable.propTypes = {
    tableConfig: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    summaryLine: PropTypes.array
};

export default ExportTable;
