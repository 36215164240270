import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';
import pyramidChart from './highcharts/configs/PyramidChart';

const PyramidChartView = highchartsChartViewFactory(pyramidChart);

PyramidChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

export default PyramidChartView;
