export const mapIndex = {
    world: {
        src: 'world',
        name: 'custom/world',
        type: 'overview',
        iso: 'world'
    },
    europe: {
        src: 'europe',
        name: 'custom/europe',
        type: 'overview',
        iso: 'europe'
    },
    africa: {
        src: 'africa',
        name: 'custom/africa',
        type: 'overview',
        iso: 'africa'
    },
    asia: {
        src: 'asia',
        name: 'custom/asia',
        type: 'overview',
        iso: 'asia'
    },
    'south-america': {
        src: 'south-america',
        name: 'custom/south-america',
        type: 'overview',
        iso: 'south-america'
    },
    'north-america': {
        src: 'north-america',
        name: 'custom/north-america',
        type: 'overview',
        iso: 'north-america'
    },
    oceania: {
        src: 'oceania',
        name: 'custom/oceania',
        type: 'overview',
        iso: 'oceania'
    },
    ad: {
        src: 'ad-all',
        name: 'countries/ad/ad-all',
        type: 'country',
        iso: 'ad'
    },
    ae: {
        src: 'ae-all',
        name: 'countries/ae/ae-all',
        type: 'country',
        iso: 'ae'
    },
    af: {
        src: 'af-all',
        name: 'countries/af/af-all',
        type: 'country',
        iso: 'af'
    },
    ag: {
        src: 'ag-all',
        name: 'countries/ag/ag-all',
        type: 'country',
        iso: 'ag'
    },
    al: {
        src: 'al-all',
        name: 'countries/al/al-all',
        type: 'country',
        iso: 'al'
    },
    am: {
        src: 'am-all',
        name: 'countries/am/am-all',
        type: 'country',
        iso: 'am'
    },
    ao: {
        src: 'ao-all',
        name: 'countries/ao/ao-all',
        type: 'country',
        iso: 'ao'
    },
    ar: {
        src: 'ar-all',
        name: 'countries/ar/ar-all',
        type: 'country',
        iso: 'ar'
    },
    as: {
        src: 'as-all',
        name: 'countries/as/as-all',
        type: 'country',
        iso: 'as'
    },
    at: {
        src: 'at-all',
        name: 'countries/at/at-all',
        type: 'country',
        iso: 'at'
    },
    au: {
        src: 'au-all',
        name: 'countries/au/au-all',
        type: 'country',
        iso: 'au'
    },
    az: {
        src: 'az-all',
        name: 'countries/az/az-all',
        type: 'country',
        iso: 'az'
    },
    ba: {
        src: 'ba-all',
        name: 'countries/ba/ba-all',
        type: 'country',
        iso: 'ba'
    },
    bb: {
        src: 'bb-all',
        name: 'countries/bb/bb-all',
        type: 'country',
        iso: 'bb'
    },
    bd: {
        src: 'bd-all',
        name: 'countries/bd/bd-all',
        type: 'country',
        iso: 'bd'
    },
    be: {
        src: 'be-all',
        name: 'countries/be/be-all',
        type: 'country',
        iso: 'be'
    },
    bf: {
        src: 'bf-all',
        name: 'countries/bf/bf-all',
        type: 'country',
        iso: 'bf'
    },
    bg: {
        src: 'bg-all',
        name: 'countries/bg/bg-all',
        type: 'country',
        iso: 'bg'
    },
    bh: {
        src: 'bh-all',
        name: 'countries/bh/bh-all',
        type: 'country',
        iso: 'bh'
    },
    bi: {
        src: 'bi-all',
        name: 'countries/bi/bi-all',
        type: 'country',
        iso: 'bi'
    },
    bj: {
        src: 'bj-all',
        name: 'countries/bj/bj-all',
        type: 'country',
        iso: 'bj'
    },
    bn: {
        src: 'bn-all',
        name: 'countries/bn/bn-all',
        type: 'country',
        iso: 'bn'
    },
    bo: {
        src: 'bo-all',
        name: 'countries/bo/bo-all',
        type: 'country',
        iso: 'bo'
    },
    br: {
        src: 'br-all',
        name: 'countries/br/br-all',
        type: 'country',
        iso: 'br'
    },
    bs: {
        src: 'bs-all',
        name: 'countries/bs/bs-all',
        type: 'country',
        iso: 'bs'
    },
    bt: {
        src: 'bt-all',
        name: 'countries/bt/bt-all',
        type: 'country',
        iso: 'bt'
    },
    bw: {
        src: 'bw-all',
        name: 'countries/bw/bw-all',
        type: 'country',
        iso: 'bw'
    },
    by: {
        src: 'by-all',
        name: 'countries/by/by-all',
        type: 'country',
        iso: 'by'
    },
    bz: {
        src: 'bz-all',
        name: 'countries/bz/bz-all',
        type: 'country',
        iso: 'bz'
    },
    ca: {
        src: 'ca-all',
        name: 'countries/ca/ca-all',
        type: 'country',
        iso: 'ca'
    },
    cd: {
        src: 'cd-all',
        name: 'countries/cd/cd-all',
        type: 'country',
        iso: 'cd'
    },
    cf: {
        src: 'cf-all',
        name: 'countries/cf/cf-all',
        type: 'country',
        iso: 'cf'
    },
    cg: {
        src: 'cg-all',
        name: 'countries/cg/cg-all',
        type: 'country',
        iso: 'cg'
    },
    ch: {
        src: 'ch-all',
        name: 'countries/ch/ch-all',
        type: 'country',
        iso: 'ch'
    },
    ci: {
        src: 'ci-all',
        name: 'countries/ci/ci-all',
        type: 'country',
        iso: 'ci'
    },
    ck: {
        src: 'ck-all',
        name: 'countries/ck/ck-all',
        type: 'country',
        iso: 'ck'
    },
    cl: {
        src: 'cl-all',
        name: 'countries/cl/cl-all',
        type: 'country',
        iso: 'cl'
    },
    cm: {
        src: 'cm-all',
        name: 'countries/cm/cm-all',
        type: 'country',
        iso: 'cm'
    },
    cn: {
        src: 'cn-all',
        name: 'countries/cn/cn-all',
        type: 'country',
        iso: 'cn'
    },
    co: {
        src: 'co-all',
        name: 'countries/co/co-all',
        type: 'country',
        iso: 'co'
    },
    cr: {
        src: 'cr-all',
        name: 'countries/cr/cr-all',
        type: 'country',
        iso: 'cr'
    },
    cu: {
        src: 'cu-all',
        name: 'countries/cu/cu-all',
        type: 'country',
        iso: 'cu'
    },
    cv: {
        src: 'cv-all',
        name: 'countries/cv/cv-all',
        type: 'country',
        iso: 'cv'
    },
    cy: {
        src: 'cy-all',
        name: 'countries/cy/cy-all',
        type: 'country',
        iso: 'cy'
    },
    cz: {
        src: 'cz-all',
        name: 'countries/cz/cz-all',
        type: 'country',
        iso: 'cz'
    },
    de: {
        src: 'de-all',
        name: 'countries/de/de-all',
        type: 'country',
        iso: 'de'
    },
    dj: {
        src: 'dj-all',
        name: 'countries/dj/dj-all',
        type: 'country',
        iso: 'dj'
    },
    dk: {
        src: 'dk-all',
        name: 'countries/dk/dk-all',
        type: 'country',
        iso: 'dk'
    },
    dm: {
        src: 'dm-all',
        name: 'countries/dm/dm-all',
        type: 'country',
        iso: 'dm'
    },
    do: {
        src: 'do-all',
        name: 'countries/do/do-all',
        type: 'country',
        iso: 'do'
    },
    dz: {
        src: 'dz-all',
        name: 'countries/dz/dz-all',
        type: 'country',
        iso: 'dz'
    },
    ec: {
        src: 'ec-all',
        name: 'countries/ec/ec-all',
        type: 'country',
        iso: 'ec'
    },
    ee: {
        src: 'ee-all',
        name: 'countries/ee/ee-all',
        type: 'country',
        iso: 'ee'
    },
    eg: {
        src: 'eg-all',
        name: 'countries/eg/eg-all',
        type: 'country',
        iso: 'eg'
    },
    eh: {
        src: 'eh-all',
        name: 'countries/eh/eh-all',
        type: 'country',
        iso: 'eh'
    },
    er: {
        src: 'er-all',
        name: 'countries/er/er-all',
        type: 'country',
        iso: 'er'
    },
    es: {
        src: 'es-all',
        name: 'countries/es/es-all',
        type: 'country',
        iso: 'es'
    },
    et: {
        src: 'et-all',
        name: 'countries/et/et-all',
        type: 'country',
        iso: 'et'
    },
    fi: {
        src: 'fi-all',
        name: 'countries/fi/fi-all',
        type: 'country',
        iso: 'fi'
    },
    fj: {
        src: 'fj-all',
        name: 'countries/fj/fj-all',
        type: 'country',
        iso: 'fj'
    },
    fo: {
        src: 'fo-all',
        name: 'countries/fo/fo-all',
        type: 'country',
        iso: 'fo'
    },
    fr: {
        src: 'fr-all',
        name: 'countries/fr/fr-all',
        type: 'country',
        iso: 'fr'
    },
    ga: {
        src: 'ga-all',
        name: 'countries/ga/ga-all',
        type: 'country',
        iso: 'ga'
    },
    gb: {
        src: 'gb-all',
        name: 'countries/gb/gb-all',
        type: 'country',
        iso: 'gb'
    },
    gd: {
        src: 'gd-all',
        name: 'countries/gd/gd-all',
        type: 'country',
        iso: 'gd'
    },
    ge: {
        src: 'ge-all',
        name: 'countries/ge/ge-all',
        type: 'country',
        iso: 'ge'
    },
    gh: {
        src: 'gh-all',
        name: 'countries/gh/gh-all',
        type: 'country',
        iso: 'gh'
    },
    gl: {
        src: 'gl-all',
        name: 'countries/gl/gl-all',
        type: 'country',
        iso: 'gl'
    },
    gm: {
        src: 'gm-all',
        name: 'countries/gm/gm-all',
        type: 'country',
        iso: 'gm'
    },
    gn: {
        src: 'gn-all',
        name: 'countries/gn/gn-all',
        type: 'country',
        iso: 'gn'
    },
    gq: {
        src: 'gq-all',
        name: 'countries/gq/gq-all',
        type: 'country',
        iso: 'gq'
    },
    gr: {
        src: 'gr-all',
        name: 'countries/gr/gr-all',
        type: 'country',
        iso: 'gr'
    },
    gt: {
        src: 'gt-all',
        name: 'countries/gt/gt-all',
        type: 'country',
        iso: 'gt'
    },
    gu: {
        src: 'gu-all',
        name: 'countries/gu/gu-all',
        type: 'country',
        iso: 'gu'
    },
    gw: {
        src: 'gw-all',
        name: 'countries/gw/gw-all',
        type: 'country',
        iso: 'gw'
    },
    gy: {
        src: 'gy-all',
        name: 'countries/gy/gy-all',
        type: 'country',
        iso: 'gy'
    },
    hn: {
        src: 'hn-all',
        name: 'countries/hn/hn-all',
        type: 'country',
        iso: 'hn'
    },
    hr: {
        src: 'hr-all',
        name: 'countries/hr/hr-all',
        type: 'country',
        iso: 'hr'
    },
    ht: {
        src: 'ht-all',
        name: 'countries/ht/ht-all',
        type: 'country',
        iso: 'ht'
    },
    hu: {
        src: 'hu-all',
        name: 'countries/hu/hu-all',
        type: 'country',
        iso: 'hu'
    },
    id: {
        src: 'id-all',
        name: 'countries/id/id-all',
        type: 'country',
        iso: 'id'
    },
    ie: {
        src: 'ie-all',
        name: 'countries/ie/ie-all',
        type: 'country',
        iso: 'ie'
    },
    il: {
        src: 'il-all',
        name: 'countries/il/il-all',
        type: 'country',
        iso: 'il'
    },
    in: {
        src: 'in-all',
        name: 'countries/in/in-all',
        type: 'country',
        iso: 'in'
    },
    iq: {
        src: 'iq-all',
        name: 'countries/iq/iq-all',
        type: 'country',
        iso: 'iq'
    },
    ir: {
        src: 'ir-all',
        name: 'countries/ir/ir-all',
        type: 'country',
        iso: 'ir'
    },
    is: {
        src: 'is-all',
        name: 'countries/is/is-all',
        type: 'country',
        iso: 'is'
    },
    it: {
        src: 'it-all',
        name: 'countries/it/it-all',
        type: 'country',
        iso: 'it'
    },
    jm: {
        src: 'jm-all',
        name: 'countries/jm/jm-all',
        type: 'country',
        iso: 'jm'
    },
    jo: {
        src: 'jo-all',
        name: 'countries/jo/jo-all',
        type: 'country',
        iso: 'jo'
    },
    jp: {
        src: 'jp-all',
        name: 'countries/jp/jp-all',
        type: 'country',
        iso: 'jp'
    },
    ke: {
        src: 'ke-all',
        name: 'countries/ke/ke-all',
        type: 'country',
        iso: 'ke'
    },
    kg: {
        src: 'kg-all',
        name: 'countries/kg/kg-all',
        type: 'country',
        iso: 'kg'
    },
    kh: {
        src: 'kh-all',
        name: 'countries/kh/kh-all',
        type: 'country',
        iso: 'kh'
    },
    km: {
        src: 'km-all',
        name: 'countries/km/km-all',
        type: 'country',
        iso: 'km'
    },
    kn: {
        src: 'kn-all',
        name: 'countries/kn/kn-all',
        type: 'country',
        iso: 'kn'
    },
    kp: {
        src: 'kp-all',
        name: 'countries/kp/kp-all',
        type: 'country',
        iso: 'kp'
    },
    kr: {
        src: 'kr-all',
        name: 'countries/kr/kr-all',
        type: 'country',
        iso: 'kr'
    },
    kv: {
        src: 'kv-all',
        name: 'countries/kv/kv-all',
        type: 'country',
        iso: 'kv'
    },
    kw: {
        src: 'kw-all',
        name: 'countries/kw/kw-all',
        type: 'country',
        iso: 'kw'
    },
    kz: {
        src: 'kz-all',
        name: 'countries/kz/kz-all',
        type: 'country',
        iso: 'kz'
    },
    la: {
        src: 'la-all',
        name: 'countries/la/la-all',
        type: 'country',
        iso: 'la'
    },
    lb: {
        src: 'lb-all',
        name: 'countries/lb/lb-all',
        type: 'country',
        iso: 'lb'
    },
    lc: {
        src: 'lc-all',
        name: 'countries/lc/lc-all',
        type: 'country',
        iso: 'lc'
    },
    li: {
        src: 'li-all',
        name: 'countries/li/li-all',
        type: 'country',
        iso: 'li'
    },
    lk: {
        src: 'lk-all',
        name: 'countries/lk/lk-all',
        type: 'country',
        iso: 'lk'
    },
    lr: {
        src: 'lr-all',
        name: 'countries/lr/lr-all',
        type: 'country',
        iso: 'lr'
    },
    ls: {
        src: 'ls-all',
        name: 'countries/ls/ls-all',
        type: 'country',
        iso: 'ls'
    },
    lt: {
        src: 'lt-all',
        name: 'countries/lt/lt-all',
        type: 'country',
        iso: 'lt'
    },
    lu: {
        src: 'lu-all',
        name: 'countries/lu/lu-all',
        type: 'country',
        iso: 'lu'
    },
    lv: {
        src: 'lv-all',
        name: 'countries/lv/lv-all',
        type: 'country',
        iso: 'lv'
    },
    ly: {
        src: 'ly-all',
        name: 'countries/ly/ly-all',
        type: 'country',
        iso: 'ly'
    },
    ma: {
        src: 'ma-all',
        name: 'countries/ma/ma-all',
        type: 'country',
        iso: 'ma'
    },
    mc: {
        src: 'mc-all',
        name: 'countries/mc/mc-all',
        type: 'country',
        iso: 'mc'
    },
    md: {
        src: 'md-all',
        name: 'countries/md/md-all',
        type: 'country',
        iso: 'md'
    },
    me: {
        src: 'me-all',
        name: 'countries/me/me-all',
        type: 'country',
        iso: 'me'
    },
    mg: {
        src: 'mg-all',
        name: 'countries/mg/mg-all',
        type: 'country',
        iso: 'mg'
    },
    mk: {
        src: 'mk-all',
        name: 'countries/mk/mk-all',
        type: 'country',
        iso: 'mk'
    },
    ml: {
        src: 'ml-all',
        name: 'countries/ml/ml-all',
        type: 'country',
        iso: 'ml'
    },
    mm: {
        src: 'mm-all',
        name: 'countries/mm/mm-all',
        type: 'country',
        iso: 'mm'
    },
    mn: {
        src: 'mn-all',
        name: 'countries/mn/mn-all',
        type: 'country',
        iso: 'mn'
    },
    mp: {
        src: 'mp-all',
        name: 'countries/mp/mp-all',
        type: 'country',
        iso: 'mp'
    },
    mr: {
        src: 'mr-all',
        name: 'countries/mr/mr-all',
        type: 'country',
        iso: 'mr'
    },
    mt: {
        src: 'mt-all',
        name: 'countries/mt/mt-all',
        type: 'country',
        iso: 'mt'
    },
    mu: {
        src: 'mu-all',
        name: 'countries/mu/mu-all',
        type: 'country',
        iso: 'mu'
    },
    mw: {
        src: 'mw-all',
        name: 'countries/mw/mw-all',
        type: 'country',
        iso: 'mw'
    },
    mx: {
        src: 'mx-all',
        name: 'countries/mx/mx-all',
        type: 'country',
        iso: 'mx'
    },
    my: {
        src: 'my-all',
        name: 'countries/my/my-all',
        type: 'country',
        iso: 'my'
    },
    mz: {
        src: 'mz-all',
        name: 'countries/mz/mz-all',
        type: 'country',
        iso: 'mz'
    },
    na: {
        src: 'na-all',
        name: 'countries/na/na-all',
        type: 'country',
        iso: 'na'
    },
    nc: {
        src: 'nc-all',
        name: 'countries/nc/nc-all',
        type: 'country',
        iso: 'nc'
    },
    ne: {
        src: 'ne-all',
        name: 'countries/ne/ne-all',
        type: 'country',
        iso: 'ne'
    },
    ng: {
        src: 'ng-all',
        name: 'countries/ng/ng-all',
        type: 'country',
        iso: 'ng'
    },
    ni: {
        src: 'ni-all',
        name: 'countries/ni/ni-all',
        type: 'country',
        iso: 'ni'
    },
    nl: {
        src: 'nl-all',
        name: 'countries/nl/nl-all',
        type: 'country',
        iso: 'nl'
    },
    no: {
        src: 'no-all',
        name: 'countries/no/no-all',
        type: 'country',
        iso: 'no'
    },
    np: {
        src: 'np-all',
        name: 'countries/np/np-all',
        type: 'country',
        iso: 'np'
    },
    nr: {
        src: 'nr-all',
        name: 'countries/nr/nr-all',
        type: 'country',
        iso: 'nr'
    },
    nz: {
        src: 'nz-all',
        name: 'countries/nz/nz-all',
        type: 'country',
        iso: 'nz'
    },
    om: {
        src: 'om-all',
        name: 'countries/om/om-all',
        type: 'country',
        iso: 'om'
    },
    pa: {
        src: 'pa-all',
        name: 'countries/pa/pa-all',
        type: 'country',
        iso: 'pa'
    },
    pe: {
        src: 'pe-all',
        name: 'countries/pe/pe-all',
        type: 'country',
        iso: 'pe'
    },
    pg: {
        src: 'pg-all',
        name: 'countries/pg/pg-all',
        type: 'country',
        iso: 'pg'
    },
    ph: {
        src: 'ph-all',
        name: 'countries/ph/ph-all',
        type: 'country',
        iso: 'ph'
    },
    pk: {
        src: 'pk-all',
        name: 'countries/pk/pk-all',
        type: 'country',
        iso: 'pk'
    },
    pl: {
        src: 'pl-all',
        name: 'countries/pl/pl-all',
        type: 'country',
        iso: 'pl'
    },
    pr: {
        src: 'pr-all',
        name: 'countries/pr/pr-all',
        type: 'country',
        iso: 'pr'
    },
    pt: {
        src: 'pt-all',
        name: 'countries/pt/pt-all',
        type: 'country',
        iso: 'pt'
    },
    pw: {
        src: 'pw-all',
        name: 'countries/pw/pw-all',
        type: 'country',
        iso: 'pw'
    },
    py: {
        src: 'py-all',
        name: 'countries/py/py-all',
        type: 'country',
        iso: 'py'
    },
    qa: {
        src: 'qa-all',
        name: 'countries/qa/qa-all',
        type: 'country',
        iso: 'qa'
    },
    ro: {
        src: 'ro-all',
        name: 'countries/ro/ro-all',
        type: 'country',
        iso: 'ro'
    },
    rs: {
        src: 'rs-all',
        name: 'countries/rs/rs-all',
        type: 'country',
        iso: 'rs'
    },
    ru: {
        src: 'ru-all',
        name: 'countries/ru/ru-all',
        type: 'country',
        iso: 'ru'
    },
    rw: {
        src: 'rw-all',
        name: 'countries/rw/rw-all',
        type: 'country',
        iso: 'rw'
    },
    sa: {
        src: 'sa-all',
        name: 'countries/sa/sa-all',
        type: 'country',
        iso: 'sa'
    },
    sb: {
        src: 'sb-all',
        name: 'countries/sb/sb-all',
        type: 'country',
        iso: 'sb'
    },
    sc: {
        src: 'sc-all',
        name: 'countries/sc/sc-all',
        type: 'country',
        iso: 'sc'
    },
    sd: {
        src: 'sd-all',
        name: 'countries/sd/sd-all',
        type: 'country',
        iso: 'sd'
    },
    se: {
        src: 'se-all',
        name: 'countries/se/se-all',
        type: 'country',
        iso: 'se'
    },
    sg: {
        src: 'sg-all',
        name: 'countries/sg/sg-all',
        type: 'country',
        iso: 'sg'
    },
    si: {
        src: 'si-all',
        name: 'countries/si/si-all',
        type: 'country',
        iso: 'si'
    },
    sk: {
        src: 'sk-all',
        name: 'countries/sk/sk-all',
        type: 'country',
        iso: 'sk'
    },
    sl: {
        src: 'sl-all',
        name: 'countries/sl/sl-all',
        type: 'country',
        iso: 'sl'
    },
    sm: {
        src: 'sm-all',
        name: 'countries/sm/sm-all',
        type: 'country',
        iso: 'sm'
    },
    sn: {
        src: 'sn-all',
        name: 'countries/sn/sn-all',
        type: 'country',
        iso: 'sn'
    },
    so: {
        src: 'so-all',
        name: 'countries/so/so-all',
        type: 'country',
        iso: 'so'
    },
    sr: {
        src: 'sr-all',
        name: 'countries/sr/sr-all',
        type: 'country',
        iso: 'sr'
    },
    ss: {
        src: 'ss-all',
        name: 'countries/ss/ss-all',
        type: 'country',
        iso: 'ss'
    },
    st: {
        src: 'st-all',
        name: 'countries/st/st-all',
        type: 'country',
        iso: 'st'
    },
    sv: {
        src: 'sv-all',
        name: 'countries/sv/sv-all',
        type: 'country',
        iso: 'sv'
    },
    sx: {
        src: 'sx-all',
        name: 'countries/sx/sx-all',
        type: 'country',
        iso: 'sx'
    },
    sy: {
        src: 'sy-all',
        name: 'countries/sy/sy-all',
        type: 'country',
        iso: 'sy'
    },
    sz: {
        src: 'sz-all',
        name: 'countries/sz/sz-all',
        type: 'country',
        iso: 'sz'
    },
    td: {
        src: 'td-all',
        name: 'countries/td/td-all',
        type: 'country',
        iso: 'td'
    },
    tf: {
        src: 'tf-all',
        name: 'countries/tf/tf-all',
        type: 'country',
        iso: 'tf'
    },
    tg: {
        src: 'tg-all',
        name: 'countries/tg/tg-all',
        type: 'country',
        iso: 'tg'
    },
    th: {
        src: 'th-all',
        name: 'countries/th/th-all',
        type: 'country',
        iso: 'th'
    },
    tj: {
        src: 'tj-all',
        name: 'countries/tj/tj-all',
        type: 'country',
        iso: 'tj'
    },
    tl: {
        src: 'tl-all',
        name: 'countries/tl/tl-all',
        type: 'country',
        iso: 'tl'
    },
    tm: {
        src: 'tm-all',
        name: 'countries/tm/tm-all',
        type: 'country',
        iso: 'tm'
    },
    tn: {
        src: 'tn-all',
        name: 'countries/tn/tn-all',
        type: 'country',
        iso: 'tn'
    },
    tr: {
        src: 'tr-all',
        name: 'countries/tr/tr-all',
        type: 'country',
        iso: 'tr'
    },
    tt: {
        src: 'tt-all',
        name: 'countries/tt/tt-all',
        type: 'country',
        iso: 'tt'
    },
    tw: {
        src: 'tw-all',
        name: 'countries/tw/tw-all',
        type: 'country',
        iso: 'tw'
    },
    tz: {
        src: 'tz-all',
        name: 'countries/tz/tz-all',
        type: 'country',
        iso: 'tz'
    },
    ua: {
        src: 'ua-all',
        name: 'countries/ua/ua-all',
        type: 'country',
        iso: 'ua'
    },
    ug: {
        src: 'ug-all',
        name: 'countries/ug/ug-all',
        type: 'country',
        iso: 'ug'
    },
    us: {
        src: 'us-all',
        name: 'countries/us/us-all',
        type: 'country',
        iso: 'us'
    },
    uy: {
        src: 'uy-all',
        name: 'countries/uy/uy-all',
        type: 'country',
        iso: 'uy'
    },
    uz: {
        src: 'uz-all',
        name: 'countries/uz/uz-all',
        type: 'country',
        iso: 'uz'
    },
    vc: {
        src: 'vc-all',
        name: 'countries/vc/vc-all',
        type: 'country',
        iso: 'vc'
    },
    ve: {
        src: 've-all',
        name: 'countries/ve/ve-all',
        type: 'country',
        iso: 've'
    },
    vi: {
        src: 'vi-all',
        name: 'countries/vi/vi-all',
        type: 'country',
        iso: 'vi'
    },
    vn: {
        src: 'vn-all',
        name: 'countries/vn/vn-all',
        type: 'country',
        iso: 'vn'
    },
    vu: {
        src: 'vu-all',
        name: 'countries/vu/vu-all',
        type: 'country',
        iso: 'vu'
    },
    wf: {
        src: 'wf-all',
        name: 'countries/wf/wf-all',
        type: 'country',
        iso: 'wf'
    },
    ws: {
        src: 'ws-all',
        name: 'countries/ws/ws-all',
        type: 'country',
        iso: 'ws'
    },
    ye: {
        src: 'ye-all',
        name: 'countries/ye/ye-all',
        type: 'country',
        iso: 'ye'
    },
    za: {
        src: 'za-all',
        name: 'countries/za/za-all',
        type: 'country',
        iso: 'za'
    },
    zm: {
        src: 'zm-all',
        name: 'countries/zm/zm-all',
        type: 'country',
        iso: 'zm'
    },
    zw: {
        src: 'zw-all',
        name: 'countries/zw/zw-all',
        type: 'country',
        iso: 'zw'
    }
};
