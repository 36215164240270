// import { formatNumber } from './utils';

// 96
export const makeOwnPostsByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} own posts`;
};

// 177
export const makeInstagramInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.interactionRate !== null ? formatNumber(numberFormat, point.interactionRate, 4, false) : 0)}%<br />${formatNumber(numberFormat, point.likes, 0, false)} likes<br />${formatNumber(numberFormat, point.comments, 0, false)} comments<br />${formatNumber(numberFormat, point.posts, 0, false)} posts`;
};

// 183
export const makeInstagramPostsAndInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    if (point.series.name === 'Interaction Rate') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.rate !== null ? formatNumber(numberFormat, point.rate, 4, false) : 0)}%`;
    } if (point.series.name === 'Posts') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} posts`;
    }
    return null;
};

// 185
export const makeInstagramPostsByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} posts`;
};

// 98
export const makeInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.interactionRate, 4, false)}%<br />${formatNumber(numberFormat, point.reactions, 0, false)} reactions<br />${formatNumber(numberFormat, point.comments, 0, false)} comments<br />${formatNumber(numberFormat, point.shares, 0, false)} shares<br />${formatNumber(numberFormat, point.posts, 0, false)} own posts`;
};

// 150
export const makeLinkedInInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.interactionRate !== null ? formatNumber(numberFormat, point.interactionRate, 4, false) : 0)}%<br />${formatNumber(numberFormat, point.likes, 0, false)} likes<br />${formatNumber(numberFormat, point.comments, 0, false)} comments<br />${formatNumber(numberFormat, point.posts, 0, false)} updates`;
};

// 158
export const makeLinkedInUpdatesAndInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    if (point.series.name === 'Interaction Rate') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.rate !== null ? formatNumber(numberFormat, point.rate, 4, false) : 0)}%`;
    } if (point.series.name === 'Updates') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} updates`;
    }
    return null;
};

// 160
export const makeLinkedInUpdatesByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} posts`;
};

// 99
export const makeOwnPostsAndInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    if (point.series.name === 'Interaction Rate') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.rate !== null ? formatNumber(numberFormat, point.rate, 4, false) : 0)}%`;
    } if (point.series.name === 'Own Posts') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} own posts`;
    }
    return null;
};

// 675
export const makeTwitterInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.interactionRate !== null ? formatNumber(numberFormat, point.interactionRate, 4, false) : 0)}%<br />${formatNumber(numberFormat, point.retweets, 0, false)} retweets<br />${formatNumber(numberFormat, point.favs, 0, false)} favorites<br />${formatNumber(numberFormat, point.replies, 0, false)} replies<br />${formatNumber(numberFormat, point.tweets, 0, false)} own tweets`;
};

// 667
export const makeTwitterMentionsByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.mentions, 0, false)} mentions`;
};

// 676
export const makeTwitterOwnTweetsAndInteractionRateByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    if (point.series.name === 'Interaction Rate') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${(point.rate !== null ? formatNumber(numberFormat, point.rate, 4, false) : 0)}%`;
    } if (point.series.name === 'Own Tweets') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.tweets, 0, false)} own tweets`;
    }

    return null;
};

// 666
export const makeTwitterOwnTweetsByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.tweets, 0, false)} own tweets`;
};

export const makeTwitterOwnTweetsImpressionsEngagementByTime = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    if (point.series.name === 'Engagements') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.value, 4, false)} engagements`;
    } if (point.series.name === 'Impressions') {
        return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.value, 4, false)} impressions`;
    }
    return null;
};

// 97
export const makeUserPostsByWeekdayAndHour = (formatNumber, numberFormat, data) => function formatter() {
    const { point } = this;
    const from = data.categoriesX[point.x];
    let to = parseInt(from, 10) + 1;
    if (to < 10) {
        to = `0${to}`;
    }
    return `<b>${data.categoriesY[point.y]}, ${from}:00 to ${to}:00</b><br />${formatNumber(numberFormat, point.posts, 0, false)} user posts`;
};

export const makeResolveTooltipFormatterByMetricId = (formatNumber, numberFormat, metricId, data) => {
    switch (metricId) {
        case '97':
            return makeUserPostsByWeekdayAndHour(formatNumber, numberFormat, data);
        case '666':
            return makeTwitterOwnTweetsByWeekdayAndHour(formatNumber, numberFormat, data);
        case '667':
            return makeTwitterMentionsByWeekdayAndHour(formatNumber, numberFormat, data);
        case '676':
            return makeTwitterOwnTweetsAndInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '675':
            return makeTwitterInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '99':
            return makeOwnPostsAndInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '160':
            return makeLinkedInUpdatesByWeekdayAndHour(formatNumber, numberFormat, data);
        case '158':
            return makeLinkedInUpdatesAndInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '150':
            return makeLinkedInInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '98':
            return makeInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '185':
            return makeInstagramPostsByWeekdayAndHour(formatNumber, numberFormat, data);
        case '183':
            return makeInstagramPostsAndInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '177':
            return makeInstagramInteractionRateByWeekdayAndHour(formatNumber, numberFormat, data);
        case '96':
            return makeOwnPostsByWeekdayAndHour(formatNumber, numberFormat, data);
        default:
            return null;
    }
};
