import React, { Component } from 'react';
import {
    tablePaginationInitialize,
    tablePaginationReset,
    tablePaginationSetPage,
    tablePaginationSetSortBy
} from 'src/actions/tablePagination';
import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withPaginationServerData from 'src/components/chartViews/fixedDataTable/tableEngine/withPaginationServerData';

const withPaginationHandling = (WrappedComponent) => {
    const WrappedComponentWithPaginationServerData = withPaginationServerData(WrappedComponent);

    class WithPaginationHandling extends Component {
        constructor(props) {
            super(props);
            this.onPaginationClick = this.onPaginationClick.bind(this);
            this.onSortChangeClick = this.onSortChangeClick.bind(this);
        }

        componentDidMount() {
            const {
                tablePaginationInitializeAction, originalServerRequest, initialOnPage, initialSortDir, initialSortBy, paginate
            } = this.props;
            const { identifier } = originalServerRequest.request;
            tablePaginationInitializeAction(identifier, paginate, initialOnPage, initialSortBy, initialSortDir);
        }

        componentWillUnmount() {
            const { tablePaginationResetAction, originalServerRequest } = this.props;
            const { identifier } = originalServerRequest.request;
            tablePaginationResetAction(identifier);
        }

        onPaginationClick(page) {
            const { tablePaginationSetPageAction, originalServerRequest } = this.props;
            const { identifier } = originalServerRequest.request;
            tablePaginationSetPageAction(identifier, page);
        }

        onSortChangeClick(sortByColumn, sortDir) {
            const { tablePaginationSetSortByAction, originalServerRequest } = this.props;
            const { identifier } = originalServerRequest.request;
            tablePaginationSetSortByAction(identifier, sortByColumn.id, sortDir);
        }

        render() {
            const cleanedProps = _omit(this.props, [
                'tablePaginationInitializeAction',
                'tablePaginationResetAction',
                'tablePaginationSetPageAction',
                'tablePaginationSetSortByAction'
            ]);
            return (
                <WrappedComponentWithPaginationServerData
                  {...cleanedProps}
                  onSortChangeClick={this.onSortChangeClick}
                  onPaginationClick={this.onPaginationClick}
                />
            );
        }
    }

    WithPaginationHandling.propTypes = {
        initialSortDir: PropTypes.string.isRequired,
        initialSortBy: PropTypes.string.isRequired,
        initialOnPage: PropTypes.number.isRequired,
        tablePaginationInitializeAction: PropTypes.func.isRequired,
        tablePaginationResetAction: PropTypes.func.isRequired,
        tablePaginationSetPageAction: PropTypes.func.isRequired,
        tablePaginationSetSortByAction: PropTypes.func.isRequired,
        originalServerRequest: customPropTypes.serverRequest.isRequired,
        paginate: PropTypes.bool.isRequired
    };

    return connect(null, {
        tablePaginationInitializeAction: tablePaginationInitialize,
        tablePaginationResetAction: tablePaginationReset,
        tablePaginationSetPageAction: tablePaginationSetPage,
        tablePaginationSetSortByAction: tablePaginationSetSortBy
    })(WithPaginationHandling);
};

export default withPaginationHandling;
