import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    getFillSize,
    getStyle,
    css,
    uniqId
} from 'src/components/scaleText/utils';

class ScaleText extends PureComponent {
    // code logic copied from https://github.com/datchley/react-scale-text
    constructor(props) {
        super(props);
        this.state = {
            size: null
        };

        this.resizing = false;
        this.invalidChild = false;
        this.mounted = false;

        this.handleResize = () => {
            if (!this.resizing) {
                requestAnimationFrame(this.handleResize.bind(this));
            }
            this.resizing = true;
        };
    }

    componentDidMount() {
        const { children } = this.props;
        this.mounted = true;
        this.invalidChild = React.Children.count(children) > 1;
        if (this.shouldResize()) {
            this.resize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentDidUpdate() {
        this.resize();
    }

    componentWillUnmount() {
        if (!this.shouldResize()) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize() {
        this.resizing = false;
        this.resize();
    }

    shouldResize() {
        return !this.invalidChild;
    }

    resize() {
        const { minFontSize, maxFontSize, widthOnly } = this.props;
        if (!this.mounted || !this.wrapper) return;
        if (this.ruler) {
            this.clearRuler();
        }
        this.createRuler();

        const fontSize = getFillSize(
            this.ruler,
            minFontSize || Number.NEGATIVE_INFINITY,
            maxFontSize || Number.POSITIVE_INFINITY,
            widthOnly
        );

        this.setState({
            size: parseFloat(fontSize, 10)
        }, () => {
            this.clearRuler();
        });
    }

    createRuler() {
        // Create copy of wrapper for sizing
        this.ruler = this.wrapper.cloneNode(true);
        this.ruler.id = uniqId();
        css(this.ruler, {
            position: 'absolute',
            top: '0px',
            left: 'calc(100vw * 2)',
            width: getStyle(this.wrapper, 'width'),
            height: getStyle(this.wrapper, 'height')
        });
        document.body.appendChild(this.ruler);
    }

    clearRuler() {
        if (this.ruler) {
            document.body.removeChild(this.ruler);
        }
        this.ruler = null;
    }

    render() {
        const { size: fontSize } = this.state;
        const { children } = this.props;
        const child = React.isValidElement(children)
            ? React.Children.only(children)
            : (<span>{children}</span>);

        const style = {
            fontSize: fontSize ? `${fontSize.toFixed(2)}px` : 'inherit',
            width: '100%',
            height: '100%'
        };

        const childProps = {
            fontSize: fontSize
                ? parseFloat(fontSize.toFixed(2))
                : 'inherit'
        };

        return (
            <div
              className="scaletext-wrapper"
              ref={(c) => { this.wrapper = c; }}
              style={style}
            >
                {
                    React.cloneElement(child, childProps)
                }
            </div>
        );
    }
}

ScaleText.propTypes = {
    children: PropTypes.node.isRequired,
    minFontSize: PropTypes.number,
    maxFontSize: PropTypes.number,
    widthOnly: PropTypes.bool
};

ScaleText.defaultProps = {
    minFontSize: Number.NEGATIVE_INFINITY,
    maxFontSize: Number.POSITIVE_INFINITY,
    widthOnly: false
};

export default ScaleText;
