import { DistinctColorArray, getColor, getDistinctColorPalette } from 'src/components/chartViews/highcharts/customColorUtils';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _indexOf from 'lodash/indexOf';
import _isNumber from 'lodash/isNumber';
import { getColorPalette } from 'src/components/chartViews/highcharts/Highcharts';
import { getCountriesFromMapObject } from './highmapsUtils';

const filterResults = (mapObject, unfilteredData) => {
    const filteredData = [];
    const countryCodesToShow = getCountriesFromMapObject(mapObject);
    unfilteredData.forEach((value) => {
        if (_isNumber(value.z) && value.countryCode !== null) {
            let currentCode = value.countryCode.toLowerCase();
            if (currentCode === 'hk') {
                currentCode = 'cn';
            }
            if (_indexOf(countryCodesToShow, currentCode) > -1) {
                filteredData.push(value);
            }
        }
    });
    return filteredData;
};

const bubbleMapChart = (props, Highcharts, formatNumber) => {
    const customColorsInUse = new DistinctColorArray();
    const {
        data, metricConfig, mapObject, width, height, customColors, numberFormat, isSampleMetric
    } = props;
    const defaultColorPalette = getColorPalette(isSampleMetric);
    const seriesData = [];
    const pages = data.infoData;
    const label = _get(metricConfig, 'metaData.label', data.valueColumn);
    const mapSeries = {
        mapData: mapObject.mapData,
        borderColor: '#606060',
        showInLegend: false,
        nullColor: 'rgba(205, 215, 225, 1)',
        // Is drilldown active? initialize callback and clickable dataPoints
        // TODO: below
        point: mapObject.drillDownCallback, // ("drillDownCallback" in mapObject) ? mapObject.drillDownCallback : {},
        data: mapObject.clickableDrillDownPoints, // ("drillDownCallback" in mapObject) ? mapObject.clickableDrillDownPoints : [],
        joinBy: ['iso-a2', 'code'],
        color: 'rgba(205, 215, 225, 1)',
        states: {
            hover: {
                color: 'rgba(240,145,44,1)'
            }
        },
        cursor: 'default' // ("drillDownCallback" in mapObject) && !isInDrillDown ? 'pointer' : 'default'
    };
    seriesData.push(mapSeries);
    pages.forEach((page) => {
        // filter and parse results
        const filteredData = filterResults(mapObject, data.hcChartData[`data${page.id}`]);
        const temp = {
            type: 'mapbubble',
            animation: false,
            dataLabels: {
                enabled: true,
                format: '{point.name}'
            },
            data: filteredData,
            minSize: 10,
            maxSize: '10%',
            name: page.name,
            profileId: page.id
        };
        if (_has(customColors, page.id)) {
            const customColor = getColor(customColors[page.id]);
            temp.color = customColor.getAsHex();
            customColorsInUse.push(customColor);
        }
        seriesData.push(temp);
    });

    return {
        chart: {
            spacingTop: 20,
            spacingBottom: 10,
            animation: false,
            backgroundColor: null,
            style: { position: 'absolute' },
            type: 'map',
            width,
            height
        },
        colors: getDistinctColorPalette(customColorsInUse, defaultColorPalette),
        legend: {
            y: 0,
            borderWidth: 0,
            margin: 0,
            itemStyle: {
                color: '#274b6d'
            },
            valueDecimals: 0
        },
        title: {
            text: null
        },
        subtitle: {
            text: null
        },
        tooltip: {
            formatter() {
                const value = formatNumber(numberFormat, this.point.z, 2);
                return `<b>${this.point.name}</b><br />${value} ${label}`;
            }
        },
        mapNavigation: {
            enabled: true
        },
        series: seriesData,
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: { visible: false },
        yAxis: { visible: false }
    };
};

export default bubbleMapChart;
