import _get from 'lodash/get';

const funnelChart = (props) => {
    const {
        data, metricConfig, width, height
    } = props;
    const seriesData = data.hcChartData;
    const { metaData } = metricConfig;
    return {
        chart: {
            type: 'funnel',
            marginTop: 25,
            marginBottom: 25,
            width,
            height
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name} ({point.y})',
                    color: 'black',
                    style: {
                        color: '#274b6d',
                        fontWeight: 'normal'
                    },
                    softConnector: true
                },
                neckWidth: '30%',
                neckHeight: '20%',
                width: '60%'
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: _get(metaData, 'dim2.name', 'Value'),
            data: seriesData
        }]
    };
};

export default funnelChart;
