import {
    CUSTOM_METRIC_DELETE_SUCCESS,
    CUSTOM_METRIC_DUPLICATE_SUCCESS,
    CUSTOM_METRIC_SAVE_AS_SUCCESS,
} from 'src/actions/customMetric';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _union from 'lodash/union';
import { combineReducers } from 'redux';
import {
    METRIC_USAGE_COUNT_UPDATE_SUCCESS, METRIC_GET_REQUEST, METRIC_GET_ERROR, METRIC_GET_SUCCESS
} from 'src/actions/metric';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import { DASHBOARD_METRICS_ADD_SUCCESS } from 'src/actions/dashboardMetrics';
import { DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS } from 'src/actions/dashboard';

export function byId(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case PREPARE_EXPORT_DATA:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS: {
            return Object.assign({}, state, payload.metrics);
        }
        case CUSTOM_METRIC_SAVE_AS_SUCCESS:
        case CUSTOM_METRIC_DUPLICATE_SUCCESS:
        case METRIC_GET_SUCCESS: {
            const { metric } = payload;
            return Object.assign({}, state, { [metric.id]: metric });
        }
        case DASHBOARD_METRICS_ADD_SUCCESS: {
            const { metric } = payload;
            if (!_has(state, [metric.id])) {
                return Object.assign({}, state, { [metric.id]: metric });
            }
            return state;
        }
        case CUSTOM_METRIC_DELETE_SUCCESS: {
            const { id } = payload;
            return _omit(state, id);
        }
        case METRIC_USAGE_COUNT_UPDATE_SUCCESS: {
            const { metricUsageUpdates } = payload;
            let newState = Object.assign({}, state);
            Object.keys(metricUsageUpdates).forEach((id) => {
                const usage = metricUsageUpdates[id];
                const currentMetric = Object.assign({}, newState[`${id}`]);
                const updatedMetric = Object.assign({}, currentMetric, { usage });
                newState = Object.assign({}, newState, { [`${id}`]: updatedMetric });
            });
            return Object.assign({}, state, newState);
        }
        default:
            return state;
    }
}

export function allIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case PREPARE_EXPORT_DATA:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS: {
            return [...state, ...Object.keys(payload.metrics)];
        }
        case CUSTOM_METRIC_SAVE_AS_SUCCESS:
        case CUSTOM_METRIC_DUPLICATE_SUCCESS:
        case METRIC_GET_SUCCESS:
        case DASHBOARD_METRICS_ADD_SUCCESS: {
            const { metric } = payload;
            return _union(state, [`${metric.id}`]);
        }
        case CUSTOM_METRIC_DELETE_SUCCESS: {
            const { id } = payload;
            return state.filter((value) => value !== id);
        }
        default:
            return state;
    }
}

function customMetricIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const { metrics } = payload;
            const ids = [];
            Object.keys(metrics).forEach((id) => {
                if (_get(metrics[id], 'isCustomMetric', false)) {
                    ids.push(id);
                }
            });
            return [...state, ...ids];
        }
        case CUSTOM_METRIC_SAVE_AS_SUCCESS:
        case CUSTOM_METRIC_DUPLICATE_SUCCESS: {
            const { metric } = payload;
            if (_get(metric, 'isCustomMetric', false)) {
                return _union(state, [`${metric.id}`]);
            }
            return state;
        }
        case CUSTOM_METRIC_DELETE_SUCCESS: {
            const { id } = payload;
            return state.filter((value) => value !== id);
        }
        default:
            return state;
    }
}

const asyncStates = combineReducers({
    get: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.metricId,
        [METRIC_GET_REQUEST],
        [METRIC_GET_SUCCESS],
        [METRIC_GET_ERROR]
    )
});

export const metrics = combineReducers({
    customMetricIds,
    allIds,
    byId,
    asyncStates
});
