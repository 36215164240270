import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/chartViews/bigNumber/singleValue.scss';
import { getRendererByType } from 'src/components/chartViews/renderers';

const SingleValue = ({ value, type, numberFormat }) => {
    const renderer = getRendererByType(type);
    return (
        <div className={styles.wrapper}>
            {renderer(value, numberFormat)}
        </div>
    );
};

SingleValue.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: customPropTypes.metaDataValueType.isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired
};

export default SingleValue;
