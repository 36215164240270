import {
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS,
    DASHBOARD_CLONE_SUCCESS,
    DASHBOARDS_GET_SUCCESS
} from 'src/actions/dashboard';
import {
    DASHBOARD_METRICS_ADD_SUCCESS,
    DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE,
    DASHBOARD_METRICS_DELETE_ERROR,
    DASHBOARD_METRICS_DELETE_REQUEST,
    DASHBOARD_METRICS_DELETE_SUCCESS,
    DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS,
    DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS,
    DASHBOARD_METRIC_CLONE_SUCCESS,
    DASHBOARD_METRIC_MOVE_SUCCESS,
    DASHBOARD_METRICS_UPDATE_NOTES_SUCCESS,
    DASHBOARD_METRICS_UPDATE_NOTES_REQUEST,
    DASHBOARD_METRICS_UPDATE_NOTES_ERROR
} from 'src/actions/dashboardMetrics';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForAction } from 'src/reducers/utils';
import { CUSTOM_METRIC_SAVE_AS_SUCCESS } from 'src/actions/customMetric';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

function byId(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
        case DASHBOARD_CLONE_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const dashboardMetrics = {};
            Object.keys(payload.dashboardMetrics).forEach((id) => {
                dashboardMetrics[id] = Object.assign({}, payload.dashboardMetrics[id].entity);
            });
            return Object.assign({}, state, dashboardMetrics);
        }
        case DASHBOARD_METRICS_DELETE_SUCCESS: {
            const { dashboardMetricId } = payload;
            return _omit(state, dashboardMetricId);
        }
        case DASHBOARD_METRICS_ADD_SUCCESS:
        case DASHBOARD_METRIC_CLONE_SUCCESS: {
            const { entity } = payload.dashboardMetric;
            return Object.assign({}, state, { [entity.id]: entity });
        }
        case DASHBOARD_METRIC_MOVE_SUCCESS: {
            const { dashboardMetric, removedDashboardMetricId } = payload;
            const { entity } = dashboardMetric;
            const cleanedDashboardMetrics = _omit(state, removedDashboardMetricId);
            return Object.assign({}, cleanedDashboardMetrics, { [entity.id]: entity });
        }
        case DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE: {
            const { relatedDashboardMetricIds } = payload;
            return _omit(state, relatedDashboardMetricIds);
        }
        default:
            return state;
    }
}

function allIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
        case DASHBOARD_CLONE_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const { dashboardMetrics } = payload;
            return [...state, ...Object.keys(dashboardMetrics)];
        }
        case DASHBOARD_METRICS_ADD_SUCCESS:
        case DASHBOARD_METRIC_CLONE_SUCCESS: {
            const { entity } = payload.dashboardMetric;
            return [...state, entity.id];
        }
        case DASHBOARD_METRIC_MOVE_SUCCESS: {
            const { dashboardMetric, removedDashboardMetricId } = payload;
            const cleanedIds = state.filter((value) => value !== removedDashboardMetricId);
            return [...cleanedIds, dashboardMetric.entity.id];
        }
        case DASHBOARD_METRICS_DELETE_SUCCESS: {
            const { dashboardMetricId } = payload;
            return state.filter((value) => value !== dashboardMetricId);
        }
        case DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE: {
            const { relatedDashboardMetricIds } = payload;
            return _difference(state, relatedDashboardMetricIds);
        }
        default:
            return state;
    }
}

function settingsById(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
        case DASHBOARD_CLONE_SUCCESS:
        case DASHBOARDS_GET_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const dashboardMetrics = {};
            Object.keys(payload.dashboardMetrics).forEach((id) => {
                dashboardMetrics[id] = payload.dashboardMetrics[id].settings;
            });
            return Object.assign({}, state, dashboardMetrics);
        }
        case DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE: {
            const { relatedDashboardMetricIds } = payload;
            return _omit(state, relatedDashboardMetricIds);
        }
        case DASHBOARD_METRICS_DELETE_SUCCESS: {
            const { dashboardMetricId } = payload;
            return _omit(state, dashboardMetricId);
        }
        case DASHBOARD_METRICS_ADD_SUCCESS:
        case DASHBOARD_METRIC_CLONE_SUCCESS: {
            const { entity, settings } = payload.dashboardMetric;
            return Object.assign({}, state, { [entity.id]: settings });
        }
        case DASHBOARD_METRIC_MOVE_SUCCESS: {
            const { removedDashboardMetricId, dashboardMetric } = payload;
            const cleanedSettings = _omit(state, removedDashboardMetricId);
            const { entity, settings } = dashboardMetric;
            return Object.assign({}, cleanedSettings, { [entity.id]: settings });
        }
        case DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS:
        case DASHBOARD_METRICS_UPDATE_NOTES_SUCCESS: {
            const { dashboardMetricId, newSettings } = payload;
            const updatedSettings = Object.assign({}, state[dashboardMetricId], newSettings);
            return Object.assign({}, state, { [dashboardMetricId]: updatedSettings });
        }
        case CUSTOM_METRIC_SAVE_AS_SUCCESS: {
            const { dashboardMetricSettings } = payload;
            let newState = Object.assign({}, state);
            dashboardMetricSettings.forEach((additionalSettings, dashboardMetricId) => {
                const updatedSettings = Object.assign({}, newState[dashboardMetricId], { settingAdditional: additionalSettings });
                newState = Object.assign({}, newState, { [dashboardMetricId]: updatedSettings });
            });
            return Object.assign({}, state, newState);
        }
        default:
            return state;
    }
}

const layoutsById = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
        case DASHBOARD_CLONE_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const dashboardMetrics = {};
            Object.keys(payload.dashboardMetrics).forEach((id) => {
                dashboardMetrics[id] = payload.dashboardMetrics[id].layout;
            });
            return Object.assign({}, state, dashboardMetrics);
        }
        case DASHBOARD_METRICS_DELETE_SUCCESS: {
            const { dashboardMetricId } = payload;
            return _omit(state, dashboardMetricId);
        }
        case DASHBOARD_METRICS_ADD_SUCCESS:
        case DASHBOARD_METRIC_CLONE_SUCCESS: {
            const { layout, entity } = payload.dashboardMetric;
            return Object.assign({}, state, { [entity.id]: layout });
        }
        case DASHBOARD_METRIC_MOVE_SUCCESS: {
            const { removedDashboardMetricId, dashboardMetric } = payload;
            const { layout, entity } = dashboardMetric;
            const cleanedLayouts = _omit(state, removedDashboardMetricId);
            return Object.assign({}, cleanedLayouts, { [entity.id]: layout });
        }
        case DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE: {
            const { relatedDashboardMetricIds } = payload;
            return _omit(state, relatedDashboardMetricIds);
        }
        case DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS: {
            const { newLayout } = payload;
            const dashboardMetricsToBeUpdated = {};
            newLayout.forEach((layout) => {
                const id = layout.i;
                const dashboardMetricCurrentState = state[id];
                if (
                    dashboardMetricCurrentState.row !== layout.y
                    || dashboardMetricCurrentState.col !== layout.x
                    || dashboardMetricCurrentState.width !== layout.w
                    || dashboardMetricCurrentState.height !== layout.h
                ) {
                    dashboardMetricsToBeUpdated[id] = Object.assign({}, dashboardMetricCurrentState, {
                        row: layout.y,
                        col: layout.x,
                        width: layout.w,
                        height: layout.h
                    });
                }
            });
            return Object.assign({}, state, dashboardMetricsToBeUpdated);
        }
        default:
            return state;
    }
};

const asyncStatesByAction = combineReducers({
    delete: createAsyncStatesReducerForAction(
        (payload) => payload.dashboardMetricId,
        [DASHBOARD_METRICS_DELETE_REQUEST],
        [DASHBOARD_METRICS_DELETE_SUCCESS, DASHBOARD_METRICS_DELETE_ERROR]
    ),
    updateNotes: createAsyncStatesReducerForAction(
        (payload) => payload.dashboardMetricId,
        [DASHBOARD_METRICS_UPDATE_NOTES_REQUEST],
        [DASHBOARD_METRICS_UPDATE_NOTES_SUCCESS, DASHBOARD_METRICS_UPDATE_NOTES_ERROR]
    )
});

export const dashboardMetrics = combineReducers({
    byId,
    allIds,
    settingsById,
    asyncStatesByAction,
    layoutsById
});
