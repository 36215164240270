import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';
import scatterChart from './highcharts/configs/ScatterChart';

const ScatterChartView = highchartsChartViewFactory(scatterChart);

ScatterChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    percent: PropTypes.bool,
    selectedDate: customPropTypes.selectedDate.isRequired,
    dateFormat: PropTypes.string.isRequired,
    customColors: customPropTypes.customColors.isRequired,
    weekDefinition: PropTypes.oneOf(['SuSa', 'MoSu']).isRequired,
    isSampleMetric: PropTypes.bool
};

ScatterChartView.defaultProps = {
    isSampleMetric: false
};

export default ScatterChartView;
