import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/metricTile.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MetricTileBody = ({ noFlex, children }) => (
    <div className={classnames(styles.body, { [styles.noFlex]: noFlex })}>
        {children}
    </div>
);

MetricTileBody.propTypes = {
    children: customPropTypes.children.isRequired,
    noFlex: PropTypes.bool
};

MetricTileBody.defaultProps = {
    noFlex: false
};

export default MetricTileBody;
