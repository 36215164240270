import _difference from 'lodash/fp/difference';
import _get from 'lodash/get';

const getRange = (chunkNumber, min, delta, precision) => parseInt((min + (chunkNumber * delta)) / precision, 10) * precision;

const getLabel = (labels, attribute) => {
    if (attribute in labels) {
        return labels[attribute];
    }
    return attribute;
};

const isLabelSet = (labels, attribute) => attribute in labels;

const mapChart = (props, Highcharts, formatNumber) => {
    const {
        data, metricConfig, mapObject, width, height, numberFormat
    } = props;

    const seriesData = [];
    let min = null;
    let max = null;
    const missingCountryCodes = [];

    // countryCode, countryname and at least one additional property is required to work properly
    // we need one primary attribute as well, to generate the ranges
    let primaryAttribute = null;
    let attributes = [];
    const labelData = [];
    const { results } = data;
    if (results.length > 0) {
        const row = results[0];
        // get first key, that is not countryCode/Name as primary Attribute
        const keys = Object.keys(row);
        attributes = _difference(keys, ['countryCode', 'countryName']);
        ({ 0: primaryAttribute } = attributes);

        const labels = _get(metricConfig, 'metaData.dim1.labels');
        if (labels !== 'undefined') {
            for (let i = 0, iLen = labels.length; i < iLen; i++) {
                if (labels[i].length >= 2) {
                    const index = labels[i][0];
                    const value = labels[i][1];
                    labelData[index] = value;
                }
            }
        }
    }
    const countries = mapObject.mapData.features;
    for (let i = 0, iLen = countries.length; i < iLen; i++) {
        const countryCode = countries[i].properties['iso-a2'];
        let result = null;
        for (let j = 0, jLen = results.length; j < jLen; j++) {
            if (results[j].countryCode && countryCode && results[j].countryCode.toLowerCase() === countryCode.toLowerCase()) {
                result = results[j];
                break;
            }
        }

        if (primaryAttribute !== null && result !== null && result[primaryAttribute] !== null) {
            if (min === null || result[primaryAttribute] < min) {
                min = parseInt(result[primaryAttribute], 10);
            }
            if (max === null || result[primaryAttribute] > max) {
                max = parseInt(result[primaryAttribute], 10);
            }

            // prepare data
            const dataTmp = {};
            for (let j = 0, jLen = attributes.length; j < jLen; j++) {
                if (attributes[j] !== primaryAttribute) {
                    dataTmp[attributes[j]] = result[attributes[j]];
                }
            }
            seriesData.push({
                value: parseInt(result[primaryAttribute], 10),
                data: dataTmp,
                code: countryCode
            });
        } else {
            missingCountryCodes.push(countryCode);
        }
    }

    for (let i = 0, iLen = missingCountryCodes.length; i < iLen; i++) {
        const countryCode = missingCountryCodes[i];
        seriesData.push({
            value: 0,
            noTooltip: true,
            code: countryCode
        });
    }

    const delta = parseInt((max - min) / 6, 10);
    const precision = Math.max(10, Math.min(100000, parseInt(10 ** (((`${max}`).length - 2) >= 0 ? (`${max}`).length - 2 : 0), 10)));
    const ranges = [];
    if (getRange(0, min, delta, precision) > 0) {
        ranges.push({
            to: getRange(0, min, delta, precision),
            color: 'rgba(19,64,117,0.05)'
        });
    }
    ranges.push({
        from: getRange(0, min, delta, precision),
        to: getRange(1, min, delta, precision),
        color: 'rgba(19,64,117,0.2)'
    });
    ranges.push({
        from: getRange(1, min, delta, precision),
        to: getRange(2, min, delta, precision),
        color: 'rgba(19,64,117,0.4)'
    });
    ranges.push({
        from: getRange(2, min, delta, precision),
        to: getRange(3, min, delta, precision),
        color: 'rgba(19,64,117,0.5)'
    });
    ranges.push({
        from: getRange(3, min, delta, precision),
        to: getRange(4, min, delta, precision),
        color: 'rgba(19,64,117,0.6)'
    });
    ranges.push({
        from: getRange(4, min, delta, precision),
        to: getRange(5, min, delta, precision),
        color: 'rgba(19,64,117,0.8)'
    });
    ranges.push({
        from: getRange(5, min, delta, precision),
        color: 'rgba(19,64,117,1)'
    });

    return {
        chart: {
            type: 'map',
            animation: false,
            backgroundColor: null,
            style: { position: 'absolute' },
            width,
            height
        },
        title: {
            text: null
        },
        subtitle: {
            text: null
        },
        legend: {
            y: 0,
            borderWidth: 0,
            margin: 0,
            itemStyle: {
                color: '#274b6d'
            },
            valueDecimals: 0,
            labelFormatter() {
                if (this.from === undefined) {
                    return `< ${formatNumber(numberFormat, this.to)}`;
                }
                if (this.to === undefined) {
                    return `> ${formatNumber(numberFormat, this.from)}`;
                }
                return `${formatNumber(numberFormat, this.from)} - ${formatNumber(numberFormat, this.to)}`;
            }
        },
        tooltip: {
            formatter() {
                if (this.point.noTooltip !== true) {
                    let markup = `<b>${this.point.name}</b><br/>${formatNumber(numberFormat, this.point.value)} ${getLabel(labelData, primaryAttribute)}`;
                    Object.keys(this.point.data).forEach((key) => {
                        if (isLabelSet(labelData, key)) {
                            const value = formatNumber(numberFormat, this.point.data[key], 2);
                            markup += `<br />${value} ${getLabel(labelData, key)}`;
                        }
                    });
                    return markup;
                }
                return `<b>${this.point.name}</b><br />n/a`;
            }
        },
        colorAxis: {
            dataClasses: ranges
        },
        series: [{
            borderColor: '#606060',
            mapData: mapObject.mapData,
            joinBy: ['iso-a2', 'code'],
            data: seriesData,
            name: 'Fans',
            states: {
                hover: {
                    color: '#f09100'
                }
            },
            point: {},
            cursor: 'default'
        }],
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: { visible: false },
        yAxis: { visible: false }
    };
};

export default mapChart;
