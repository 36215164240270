import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { PAGE_LENGTH } from 'src/components/chartViews/fixedDataTable/utils';
import PropTypes from 'prop-types';
import withPaginationHandling from 'src/components/chartViews/fixedDataTable/tableEngine/withPaginationHandling';
import Table from './tableEngine/Table';

const TableWithPaginationHandling = withPaginationHandling(Table);

class ServerSideTable extends Component {
    render() {
        const {
            totalResultsCount,
            settings,
            tableConfig,
            paginate,
            results,
            serverRequest,
        } = this.props;

        const { columns } = tableConfig;
        const hideColumns = _get(settings, 'settingAdditional.hideColumns', tableConfig.hideColumns);
        const cleanedProps = _omit(this.props, [
            'totalResultsCount',
            'results',
        ]);

        let adjustedTotalResultsCount = totalResultsCount;
        if (paginate) {
            const settingAdditionalLimit = parseInt(_get(settings, 'settingAdditional.limit', 0), 10);
            // if there is a limit set for the dashboardMetric we need to use this as resultCount cap, otherwise use totalResultCount
            adjustedTotalResultsCount = (settingAdditionalLimit > 0) ? Math.min(totalResultsCount, settingAdditionalLimit) : totalResultsCount;
        }

        return (
            <TableWithPaginationHandling
              hiddenColumns={hideColumns}
              columns={columns}
              initialOnPage={0}
              initialSortBy={tableConfig.sortBy}
              initialSortDir={tableConfig.sortDir}
              initialResults={results}
              key={serverRequest.request.identifier}
              paginate={paginate}
              tableConfig={tableConfig}
              totalResultsCount={adjustedTotalResultsCount}
              pageLength={PAGE_LENGTH}
              originalServerRequest={serverRequest}
              {...cleanedProps}
            />
        );
    }
}

ServerSideTable.propTypes = {
    totalResultsCount: PropTypes.number.isRequired,
    settings: customPropTypes.dashboardMetricSettings,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    tableConfig: customPropTypes.tableConfig.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    paginate: PropTypes.bool,
    serverRequest: customPropTypes.serverRequest.isRequired
};

ServerSideTable.defaultProps = {
    paginate: false
};

export default ServerSideTable;
