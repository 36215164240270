import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _now from 'lodash/now';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import { createSingleServerRequestForPagination } from 'src/requestHandling/metricDataRequests';
import { makeSelectPaginationAndSorting } from 'src/selectors/tablePagination';
import { makeSelectServerDataForJobHash } from 'src/selectors/serverData';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import ResultsWrapper from 'src/components/chartViews/fixedDataTable/ResultsWrapper';
import { serverDataRequest } from 'src/actions/serverData';

const extractPaginatedResultByFormat = (data) => _get(data, 'results', null);

const withPaginationServerData = (WrappedComponent) => {
    class WithPaginationServerData extends Component {
        constructor(props) {
            super(props);
            this.state = {
                results: new ResultsWrapper(props.results),
            };
        }

        componentDidUpdate(prevProps) {
            const {
                isInitialState, serverRequestForPagination, requested, serverDataRequestAction
            } = this.props;
            if (!isInitialState && serverRequestForPagination !== prevProps.serverRequestForPagination && requested === false) {
                const { request, endpointInfo } = serverRequestForPagination;
                serverDataRequestAction(request.identifier, [request], endpointInfo);
            }
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (nextProps.results !== null) {
                const currentResult = new ResultsWrapper(nextProps.results);
                if (currentResult !== prevState.results) {
                    return {
                        results: currentResult
                    };
                }
                return null;
            }
            return null;
        }

        render() {
            const cleanedProps = _omit(this.props, [
                'initialResults', 'loading', 'requestDefinition'
            ]);
            const { results } = this.state;
            const { loading, error } = this.props;
            // In case of error, don't render the table at all since the table does not support internal error state at the moment
            if (error) {
                return <div>{error.message}</div>;
            }
            return (
                <WrappedComponent
                  {...cleanedProps}
                  results={results}
                  rowsCount={results.getSize()}
                  isLoading={loading}
                />
            );
        }
    }

    WithPaginationServerData.propTypes = {
        initialResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        initialSortDir: PropTypes.string.isRequired,
        initialSortBy: PropTypes.string.isRequired,
        initialOnPage: PropTypes.number.isRequired,
        isInitialState: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        results: PropTypes.array,
        error: customPropTypes.dataLoadingError,
        serverDataRequestAction: PropTypes.func.isRequired,
        serverRequestForPagination: customPropTypes.serverRequest,
        paginationAndSorting: customPropTypes.paginationAndSorting,
        requested: PropTypes.bool
    };

    const makeMapStateToProps = () => {
        const selectServerDataForJobHash = makeSelectServerDataForJobHash(_now());

        const selectPaginationAndSorting = makeSelectPaginationAndSorting();
        const memoizedCreateServerRequestForPagination = memoizeOne(createSingleServerRequestForPagination);
        return (state, ownProps) => {
            const {
                initialOnPage,
                initialSortDir,
                initialSortBy,
                originalServerRequest
            } = ownProps;

            const paginationAndSorting = selectPaginationAndSorting(state, originalServerRequest.request.identifier);
            const onPage = _get(paginationAndSorting, 'page', initialOnPage);
            const columnsSortBy = _get(paginationAndSorting, 'sortBy', initialSortBy);
            const columnsSortDir = _get(paginationAndSorting, 'sortDir', initialSortDir);
            const isInitialState = initialOnPage === onPage && initialSortBy === columnsSortBy && initialSortDir === columnsSortDir;
            if (isInitialState) {
                return {
                    results: ownProps.initialResults,
                    loading: false,
                    error: null,
                    isInitialState,
                    onPage,
                    columnsSortBy,
                    columnsSortDir
                };
            }
            const serverRequestForPagination = memoizedCreateServerRequestForPagination(
                originalServerRequest,
                paginationAndSorting
            );
            const {
                data, error, loading, requested
            } = selectServerDataForJobHash(state, serverRequestForPagination.request.hash);

            const results = extractPaginatedResultByFormat(data);
            return {
                results,
                loading,
                error,
                requested,
                serverRequestForPagination,
                isInitialState,
                onPage,
                columnsSortBy,
                columnsSortDir
            };
        };
    };

    return connect(makeMapStateToProps, {
        serverDataRequestAction: serverDataRequest
    })(WithPaginationServerData);
};

export default withPaginationServerData;
