import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import React from 'react';
import { SortTypes } from 'src/components/chartViews/fixedDataTable/utils';
import styles from 'src/stylesheets/table/exportTable.scss';
import PropTypes from 'prop-types';
import { hyphenateSync } from 'hyphen/en';

const TableHeaderCell = (props) => {
    const {
        sortDir,
        width,
        className,
        value
    } = props;
    let sortIcon = null;

    if (sortDir === SortTypes.DESC) {
        sortIcon = <IcomoonIcon icon="chevron-small-down" className={styles.icon} />;
    }

    if (sortDir === SortTypes.ASC) {
        sortIcon = <IcomoonIcon icon="chevron-small-up" className={styles.icon} />;
    }

    const isActivelySorted = !!(sortDir);

    // We are currently hyphenating assuming English text, which seems to be a better choice than no hyphenation at all,
    // even if used for languages other than English. We might want to implement more sophisticated language settings
    // in the future on metric-level to allow for more accurate hyphenation.
    const content = hyphenateSync(value);

    return (
        <th style={{ width }} className={classnames(styles.tableHeaderCell, className, { [styles.activelySorted]: isActivelySorted })}>
            {content} <span className={styles.icon}>{sortIcon}</span>
        </th>
    );
};

TableHeaderCell.propTypes = {
    sortDir: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default TableHeaderCell;
