import React from 'react';
import ChangeValue from 'src/components/chartViews/ChangeValue';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/chartViews/topContent/topContentMetricData.scss';
import _upperFirst from 'lodash/upperFirst';
import withTooltip from 'src/components/withToolTip';

const DivWithToolTip = withTooltip((prop) => <div {...prop} />);

const TopContentMetricData = (props) => {
    const { data, numberFormat } = props;
    return (
        <div>
            {
                data.map((item) => {
                    const { name, changeValue, comparisonSentence } = item;
                    const { value, type } = changeValue;
                    return (
                        <div key={`${name}`} className={styles.wrapper}>
                            <div className={styles.name}>{_upperFirst(name)}: </div>
                            <DivWithToolTip tooltip={comparisonSentence}><ChangeValue value={value} type={type} numberFormat={numberFormat} /></DivWithToolTip>
                        </div>
                    );
                })
            }
        </div>
    );
};

TopContentMetricData.propTypes = {
    data: customPropTypes.topContentData.isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired
};

export default TopContentMetricData;
