import { DistinctColorArray, getColor, getDistinctColorPalette } from 'src/components/chartViews/highcharts/customColorUtils';
import _has from 'lodash/has';
import { getColorPalette } from 'src/components/chartViews/highcharts/Highcharts';

const pieChart = (props, Highcharts, formatNumber) => {
    const customColorsInUse = new DistinctColorArray();
    const {
        data, width, height, customColors, numberFormat, isSampleMetric
    } = props;
    const pages = data.infoData;
    const seriesChartData = [];
    const defaultColorPalette = getColorPalette(isSampleMetric);
    for (let i = 0, iLen = pages.length; i < iLen; i += 1) {
        const temp = Object.assign({}, data.hcChartData[`data${pages[i].id}`]);
        if (_has(customColors, pages[i].id)) {
            const customColor = getColor(customColors[pages[i].id]);
            temp.color = customColor.getAsHex();
            customColorsInUse.push(customColor);
        }
        seriesChartData.push(temp);
    }
    const chartData = [{
        name: 'share',
        data: seriesChartData
    }];

    return {
        chart: {
            defaultSeriesType: 'pie',
            width,
            height
        },
        colors: getDistinctColorPalette(customColorsInUse, defaultColorPalette),

        tooltip: {
            formatter() {
                return `<b>${this.point.name}</b><br/>${formatNumber(numberFormat, this.y)}<br/>${formatNumber(numberFormat, this.percentage, 1)}%`;
            }
        },
        series: chartData
    };
};

export default pieChart;
