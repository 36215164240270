import { DistinctColorArray, getColor, getDistinctColorPalette } from 'src/components/chartViews/highcharts/customColorUtils';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { getColorPalette } from 'src/components/chartViews/highcharts/Highcharts';
import { getAxisType, getDateOptionsByIntervalType, getStartOfWeek } from './utils';

const scatterChart = (props, Highcharts, formatNumber) => {
    const customColorsInUse = new DistinctColorArray();
    const {
        data,
        selectedDate,
        dateFormat,
        metricConfig,
        width,
        height,
        percent,
        customColors,
        weekDefinition,
        numberFormat,
        isSampleMetric
    } = props;
    const defaultColorPalette = getColorPalette(isSampleMetric);
    const xAxisType = getAxisType('dim2', data);
    const { metaData } = metricConfig;
    let xAxisDateTimeLabelFormats;
    let xAxisMinTickInterval;

    if (xAxisType === 'datetime') {
        const dateOptions = getDateOptionsByIntervalType(selectedDate.interval, dateFormat, weekDefinition);
        xAxisDateTimeLabelFormats = dateOptions.dateFormat;
        xAxisMinTickInterval = dateOptions.minTickInterval;
    }

    // multiple series?
    const series = [];
    const pages = data.infoData;
    const boundaries = {};

    for (let i = 0, iLen = pages.length; i < iLen; i++) {
        const temp = {
            name: pages[i].name,
            data: data.hcChartData[`data${pages[i].id}`]
        };

        if (_has(customColors, pages[i].id)) {
            const customColor = getColor(customColors[pages[i].id]);
            temp.color = customColor.getAsHex();
            customColorsInUse.push(customColor);
        }
        series.push(temp);

        if (!_has(metaData, 'dim2.min') || !_has(metaData, 'dim2.max') || !_has(metaData, 'dim3.min') || !_has(metaData, 'dim3.max')) {
            // get min and max values to set the axes boundaries when there are no boundaries set in the metadata
            for (let j = 0, jLen = temp.data.length; j < jLen; j++) {
                const curData = temp.data[j];
                // ensure that all x and y values are floats
                curData.x = parseFloat(curData.x);
                curData.y = parseFloat(curData.y);
                if (Object.keys(boundaries).length === 0) {
                    boundaries.xMin = curData.x;
                    boundaries.xMax = curData.x;
                    boundaries.yMin = curData.y;
                    boundaries.yMax = curData.y;
                } else {
                    if (curData.x < boundaries.xMin) {
                        boundaries.xMin = curData.x;
                    }
                    if (curData.x > boundaries.xMax) {
                        boundaries.xMax = curData.x;
                    }
                    if (curData.y < boundaries.yMin) {
                        boundaries.yMin = curData.y;
                    }
                    if (curData.y > boundaries.yMax) {
                        boundaries.yMax = curData.y;
                    }
                }
            }
        }
    }

    // set final boundaries for the axes
    let xMin;
    let xMax;
    if (xAxisType === 'datetime') {
        const xMinDate = new Date(boundaries.xMin);
        const xMaxDate = new Date(boundaries.xMax);
        xMinDate.setHours(0, 0, 0, 0);
        xMaxDate.setHours(0, 0, 0, 0);
        xMin = ((xMinDate / 1000) - 43200) * 1000;
        xMax = ((xMaxDate / 1000) + 129600) * 1000;
    } else {
        xMin = _get(metaData, 'dim2.min', boundaries.xMin - ((boundaries.xMax - boundaries.xMin) * 0.1));
        xMax = _get(metaData, 'dim2.max', boundaries.xMax + ((boundaries.xMax - boundaries.xMin) * 0.1));
    }
    const yMin = _get(metaData, 'dim3.min', boundaries.yMin - ((boundaries.yMax - boundaries.yMin) * 0.15));
    const yMax = _get(metaData, 'dim3.max', boundaries.yMax + ((boundaries.yMax - boundaries.yMin) * 0.15));

    const dim2Label = _has(metaData, 'dim2.name') ? `${metaData.dim2.name}: ` : '';
    const dim3Label = _has(metaData, 'dim3.name') ? `${metaData.dim3.name}: ` : '';
    return {
        chart: {
            defaultSeriesType: 'scatter',
            width,
            height
        },
        colors: getDistinctColorPalette(customColorsInUse, defaultColorPalette),
        xAxis: {
            type: (xAxisType === 'datetime') ? xAxisType : 'linear',
            dateTimeLabelFormats: (xAxisType === 'datetime') ? xAxisDateTimeLabelFormats : null,
            minTickInterval: (xAxisType === 'datetime') ? xAxisMinTickInterval : null,
            title: {
                text: _get(metaData, 'dim2.name', null),
                style: {
                    color: '#585858',
                    fontWeight: 'bold'
                }
            },
            gridLineWidth: 0,
            lineColor: '#C0D0E0',
            tickColor: '#C0D0E0',
            min: _get(metaData, 'dim2.min', null),
            max: _get(metaData, 'dim2.max', null),
            plotLines: [{
                color: '#C0D0E0',
                width: _get(metaData, 'dim2.plotLine', false) === true && xAxisType !== 'datetime' ? 1 : 0,
                value: (xMin + xMax) / 2
            }],
            startOfWeek: getStartOfWeek(weekDefinition)
        },
        yAxis: [{
            title: {
                text: _get(metaData, 'dim3.name', null),
                style: {
                    color: '#585858',
                    fontWeight: 'bold'
                }
            },
            lineColor: '#C0D0E0',
            lineWidth: 1,
            tickColor: '#C0D0E0',
            gridLineWidth: _get(metaData, 'dim3.plotLine') === true ? 0 : 1,
            tickWidth: 1,
            gridZIndex: 1,
            min: _get(metaData, 'dim3.min', null),
            max: _get(metaData, 'dim3.max', null),
            plotLines: [{
                color: '#C0D0E0',
                width: _get(metaData, 'dim3.plotLine', false) === true ? 1 : 0,
                value: (yMin + yMax) / 2
            }],
            startOfWeek: getStartOfWeek(weekDefinition)
        }],
        tooltip: {
            formatter() {
                const renderPointAsPercentage = (point) => `<b>${point.series.name}</b><br />${formatNumber(numberFormat, (point.y / point.total) * 100)}%<br />${formatNumber(point.y, 2)}`;
                const renderPoint = (point) => `<b>${point.series.name}</b><br />${dim2Label}${point.key}<br />${dim3Label}${formatNumber(numberFormat, point.y, 4)}`;
                const renderPointAsDate = (point) => {
                    const dateTime = new Date(point.key);
                    const dateSplit = {
                        year: dateTime.getFullYear(),
                        month: dateTime.getMonth() < 9 ? `0${(dateTime.getMonth() + 1)}` : (dateTime.getMonth() + 1),
                        day: dateTime.getDate() < 10 ? `0${dateTime.getDate()}` : dateTime.getDate(),
                        hours: dateTime.getUTCHours() < 10 ? `0${dateTime.getUTCHours()}` : dateTime.getUTCHours(),
                        minutes: dateTime.getMinutes() < 10 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes(),
                        seconds: dateTime.getSeconds() < 10 ? `0${dateTime.getSeconds()}` : dateTime.getSeconds()
                    };

                    return `<b>${point.series.name}</b><br />${dim2Label}${dateSplit.year}-${dateSplit.month}-${dateSplit.day} ${dateSplit.hours}:${dateSplit.minutes}:${dateSplit.seconds}<br />${dim3Label}${formatNumber(numberFormat, point.y, 4)}`;
                };

                let tooltip;
                if (percent) {
                    tooltip = renderPointAsPercentage(this);
                } else if (xAxisType === 'datetime') {
                    tooltip = renderPointAsDate(this);
                } else {
                    tooltip = renderPoint(this);
                }

                return tooltip;
            }
        },
        series
    };
};

export default scatterChart;
