import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import bubbleChart from './highcharts/configs/BubbleChart';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';

const BubbleChartView = highchartsChartViewFactory(bubbleChart);

BubbleChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    weekDefinition: PropTypes.oneOf(['SuSa', 'MoSu']).isRequired
};

export default BubbleChartView;
