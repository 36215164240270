/* eslint-disable max-classes-per-file, no-restricted-properties */
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isNumber from 'lodash/isNumber';

export class DistinctColorArray {
    constructor() {
        this.colorsInArray = {};
        this.colorsArray = [];
    }

    push(color) {
        if (!_has(this.colorsInArray, color.getAsHex())) {
            this.colorsArray.push(color);
            this.colorsInArray[color.getAsHex()] = true;
        }
    }

    get() {
        return this.colorsArray;
    }
}

class Color {
    constructor(hexCode) {
        this.hexCode = hexCode;
        this.calculatedColors = {};
    }

    getAsHex() {
        return this.hexCode;
    }

    getAsRgb() {
        if (_has(this.calculatedColors, 'rgb')) {
            return this.calculatedColors.rgb;
        }
        const result = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(this.getAsHex());
        if (result) {
            const rgb = {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            };
            this.calculatedColors.rgb = rgb;
            return rgb;
        }
        throw new Error('Color conversion hex to rgb failed.');
    }

    getAsRgba(alpha = 1) {
        if (!_isNumber(alpha)) {
            throw new Error('alpha needs to be numeric.');
        }
        const rgb = this.getAsRgb();
        return `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`;
    }

    getAsXyz() {
        if (_has(this.calculatedColors, 'xyz')) {
            return this.calculatedColors.xyz;
        }
        const rgb = this.getAsRgb();
        let varR = parseFloat(rgb.r / 255);
        let varG = parseFloat(rgb.g / 255);
        let varB = parseFloat(rgb.b / 255);

        if (varR > 0.04045) {
            varR = Math.pow((varR + 0.055) / 1.055, 2.4);
        } else {
            varR /= 12.92;
        }

        if (varG > 0.04045) {
            varG = Math.pow((varG + 0.055) / 1.055, 2.4);
        } else {
            varG /= 12.92;
        }

        if (varB > 0.04045) {
            varB = Math.pow((varB + 0.055) / 1.055, 2.4);
        } else {
            varB /= 12.92;
        }

        varR *= 100;
        varG *= 100;
        varB *= 100;

        const x = (varR * 0.4124) + (varG * 0.3576) + (varB * 0.1805);
        const y = (varR * 0.2126) + (varG * 0.7152) + (varB * 0.0722);
        const z = (varR * 0.0193) + (varG * 0.1192) + (varB * 0.9505);
        const xyz = {
            x,
            y,
            z
        };
        this.calculatedColors.xyz = xyz;
        return xyz;
    }

    getAsLab() {
        if (_has(this.calculatedColors, 'lab')) {
            return this.calculatedColors.lab;
        }
        const xyz = this.getAsXyz();

        const refX = 95.047;
        const refY = 100.000;
        const refZ = 108.883;

        let varX = xyz.x / refX;
        let varY = xyz.y / refY;
        let varZ = xyz.z / refZ;

        if (varX > 0.008856) {
            varX = Math.pow(varX, (1 / 3));
        } else {
            varX = (7.787 * varX) + (16 / 116);
        }

        if (varY > 0.008856) {
            varY = Math.pow(varY, (1 / 3));
        } else {
            varY = (7.787 * varY) + (16 / 116);
        }

        if (varZ > 0.008856) {
            varZ = Math.pow(varZ, (1 / 3));
        } else {
            varZ = (7.787 * varZ) + (16 / 116);
        }

        const cieL = (116 * varY) - 16;
        const cieA = 500 * (varX - varY);
        const cieB = 200 * (varY - varZ);

        const lab = {
            l: cieL,
            a: cieA,
            b: cieB
        };

        this.calculatedColors.lab = lab;
        return lab;
    }
}

const colorCache = {};

export const getColor = (hexCode) => {
    const color = _get(colorCache, hexCode, false);
    if (color) {
        return color;
    }
    const newColor = new Color(hexCode);
    colorCache[hexCode] = newColor;
    return newColor;
};

export const calculateDistance = (color1, color2) => {
    const lab1 = color1.getAsLab();
    const lab2 = color2.getAsLab();
    return Math.sqrt(Math.pow((lab2.l - lab1.l), 2) + Math.pow((lab2.a - lab1.a), 2) + Math.pow((lab2.b - lab1.b), 2));
};

export const getDistinctColorPalette = (colorsInUse, colorPaletteArray) => {
    const copiedPalette = [...colorPaletteArray];
    const colorsInUseArray = colorsInUse.get();
    colorsInUseArray.forEach((colorInUse) => {
        if (copiedPalette.length > 5) {
            let shortestDistance = null;
            let shortestDistanceColorHex = null;
            copiedPalette.forEach((hexValue) => {
                const distance = calculateDistance(colorInUse, getColor(hexValue));
                if (shortestDistance === null || distance < shortestDistance) {
                    shortestDistance = distance;
                    shortestDistanceColorHex = hexValue;
                }
            });

            if (shortestDistanceColorHex !== null) {
                const index = copiedPalette.indexOf(shortestDistanceColorHex);
                if (index >= 0) {
                    copiedPalette.splice(index, 1);
                }
            }
        }
    });
    return copiedPalette;
};

/* eslint-enable max-classes-per-file, no-restricted-properties */
