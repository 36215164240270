import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/appLogo.scss';

const AppLogo = (props) => {
    const { logoUrl, big, isExport } = props;

    return (
        <img
          className={classnames(styles.logo, { [styles.big]: big, [styles.export]: isExport })}
          src={logoUrl}
          alt="logo"
        />
    );
};

AppLogo.propTypes = {
    logoUrl: PropTypes.string.isRequired,
    big: PropTypes.bool,
    isExport: PropTypes.bool
};

AppLogo.defaultProps = {
    big: false,
    isExport: false
};

export default AppLogo;
