import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import styles from 'src/stylesheets/carousel.scss';

const Carousel = (props) => {
    const {
        dots,
        slidesToShow,
        speed,
        infinite,
        children,
        slidesToScroll,
        responsive,
        autoplay,
        swipeToSlide,
        draggable,
        autoplaySpeed,
        lazyLoad
    } = props;

    return (
        <Slider
          dots={dots}
          slidesToShow={slidesToShow}
          speed={speed}
          infinite={infinite}
          adaptiveHeight
          className={styles.slickSlider}
          responsive={responsive}
          slidesToScroll={slidesToScroll}
          autoplay={autoplay}
          swipeToSlide={swipeToSlide}
          draggable={draggable}
          autoplaySpeed={autoplaySpeed}
          lazyLoad={lazyLoad}
        >
            {children}
        </Slider>
    );
};

Carousel.propTypes = {
    dots: PropTypes.bool,
    slidesToShow: PropTypes.number,
    speed: PropTypes.number,
    infinite: PropTypes.bool,
    children: customPropTypes.children,
    slidesToScroll: PropTypes.number,
    responsive: PropTypes.array,
    autoplay: PropTypes.bool,
    swipeToSlide: PropTypes.bool,
    draggable: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    lazyLoad: PropTypes.bool
};

Carousel.defaultProps = {
    dots: false,
    slidesToShow: 3,
    speed: 500,
    infinite: false,
    slidesToScroll: 1,
    responsive: [],
    autoplay: false,
    swipeToSlide: true,
    draggable: false,
    autoplaySpeed: 5000,
    lazyLoad: false
};

export default Carousel;
