import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import { combineReducers } from 'redux';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case PREPARE_EXPORT_DATA: {
            const { adAccountCampaigns } = payload;
            return Object.keys(adAccountCampaigns);
        }
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return Object.keys(accountAuthenticationEntities.adAccountCampaigns);
        }
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { adAccountCampaigns } = payload;
            return Object.keys(adAccountCampaigns);
        }
        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case PREPARE_EXPORT_DATA: {
            const { adAccountCampaigns } = payload;
            return Object.assign({}, adAccountCampaigns);
        }
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return Object.assign({}, accountAuthenticationEntities.adAccountCampaigns);
        }
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { adAccountCampaigns } = payload;
            return Object.assign({}, adAccountCampaigns);
        }
        default:
            return state;
    }
};

export const adAccountCampaigns = combineReducers({
    allIds,
    byId
});
