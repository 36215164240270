import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import {
    POST_TAG_ADD_SUCCESS,
    POST_TAG_EDIT_SUCCESS,
    POST_TAG_EDIT_REQUEST,
    POST_TAG_EDIT_ERROR,
    POST_TAGS_REMOVE_SUCCESS,
    SORT_POST_TAGS,
    TOGGLE_ARCHIVED_POST_TAGS_FILTER,
    POST_TAGS_ARCHIVE_ERROR,
    POST_TAGS_ARCHIVE_SUCCESS,
    POST_TAGS_FORCE_ARCHIVE_ERROR,
    POST_TAGS_ARCHIVE_REQUEST,
    POST_TAGS_FORCE_ARCHIVE_REQUEST,
    POST_TAGS_UNARCHIVE_ERROR,
    POST_TAGS_UNARCHIVE_REQUEST,
    POST_TAGS_UNARCHIVE_SUCCESS,
    POST_TAG_FORCE_REMOVE_SUCCESS,
    POST_TAGS_FORCE_ARCHIVE_SUCCESS,
    POST_TAG_AUTOMATION_DEACTIVATE_REQUEST,
    POST_TAG_AUTOMATION_ACTIVATE_REQUEST,
    POST_TAG_AUTOMATION_DEACTIVATE_ERROR,
    POST_TAG_AUTOMATION_DEACTIVATE_SUCCESS,
    POST_TAG_AUTOMATION_ACTIVATE_ERROR,
    POST_TAG_AUTOMATION_ACTIVATE_SUCCESS,
} from 'src/actions/postTags';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates, createAsyncStatesReducerForAction } from 'src/reducers/utils';
import { POST_TAG_RULE_ADD_SUCCESS, POST_TAG_RULE_DELETE_SUCCESS } from 'src/actions/postTagRule';
import _filter from 'lodash/filter';

const archivedPostTagIds = (state = [], actions) => {
    const { type, payload } = actions;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const { postTags } = payload;
            const { archivedTagIds } = postTags;
            return archivedTagIds;
        }
        case POST_TAGS_REMOVE_SUCCESS:
        case POST_TAG_FORCE_REMOVE_SUCCESS: {
            const { ids } = payload;
            return _difference(state, ids);
        }
        case POST_TAGS_ARCHIVE_SUCCESS:
        case POST_TAGS_FORCE_ARCHIVE_SUCCESS: {
            const { postTags } = payload;
            return _uniq([...state, ...Object.keys(postTags)]);
        }
        case POST_TAGS_UNARCHIVE_SUCCESS: {
            const { postTags } = payload;
            return _difference(state, [...Object.keys(postTags)]);
        }
        case POST_TAG_EDIT_SUCCESS: {
            const { postTag } = payload;
            if (postTag) {
                const { id, archived } = postTag;
                if (archived) {
                    return _uniq([...state, ...[id]]);
                }
                return _difference(state, [id]);
            }
            return state;
        }
        default: {
            return state;
        }
    }
};

const activePostTagIds = (state = [], actions) => {
    const { type, payload } = actions;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            const { postTags } = payload;
            const { activeTagIds } = postTags;
            return activeTagIds;
        }
        case POST_TAG_ADD_SUCCESS: {
            const { postTag } = payload;
            return [...state, postTag.id];
        }
        case POST_TAGS_REMOVE_SUCCESS:
        case POST_TAG_FORCE_REMOVE_SUCCESS: {
            const { ids } = payload;
            return _difference(state, ids);
        }
        case POST_TAGS_UNARCHIVE_SUCCESS: {
            const { postTags } = payload;
            return _uniq([...state, ...Object.keys(postTags)]);
        }
        case POST_TAGS_ARCHIVE_SUCCESS:
        case POST_TAGS_FORCE_ARCHIVE_SUCCESS: {
            const { postTags } = payload;
            return _difference(state, [...Object.keys(postTags)]);
        }
        case POST_TAG_EDIT_SUCCESS: {
            const { postTag } = payload;
            if (postTag) {
                const { id, archived } = postTag;
                if (archived) {
                    return _difference(state, [id]);
                }
                return _uniq([...state, ...[id]]);
            }
            return state;
        }
        default: {
            return state;
        }
    }
};

const byId = (state = {}, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { postTags } = payload;
            return Object.assign({}, state, postTags.tagsById);
        }
        case POST_TAGS_UNARCHIVE_SUCCESS:
        case POST_TAGS_ARCHIVE_SUCCESS:
        case POST_TAGS_FORCE_ARCHIVE_SUCCESS: {
            const { postTags } = payload;
            return Object.assign({}, state, postTags);
        }
        case POST_TAG_ADD_SUCCESS:
        case POST_TAG_EDIT_SUCCESS:
        case POST_TAG_AUTOMATION_ACTIVATE_SUCCESS:
        case POST_TAG_AUTOMATION_DEACTIVATE_SUCCESS: {
            const { postTag } = payload;
            if (postTag !== null) {
                return Object.assign({}, state, { [postTag.id]: postTag });
            }
            return state;
        }
        case POST_TAGS_REMOVE_SUCCESS:
        case POST_TAG_FORCE_REMOVE_SUCCESS: {
            const { ids } = payload;
            return _omit(state, ids);
        }
        case POST_TAG_RULE_ADD_SUCCESS: {
            const { postTagRule } = payload;
            if (postTagRule !== null) {
                const postTag = state[postTagRule.postTagId];
                return Object.assign({}, state, {
                    [postTagRule.postTagId]: Object.assign({}, postTag, { postTagRuleIds: [...postTag.postTagRuleIds, postTagRule.id] })
                });
            }
            return state;
        }
        case POST_TAG_RULE_DELETE_SUCCESS: {
            const { postTagId, postTagRuleId } = payload;
            const postTag = state[postTagId];
            return Object.assign({}, state, {
                [postTagId]: Object.assign({}, postTag, { postTagRuleIds: _filter(postTag.postTagRuleIds, (value) => value !== postTagRuleId) })
            });
        }
        default: {
            return state;
        }
    }
};

const ascendingSortDir = 'asc';
const descendingSortDir = 'desc';

const defaultState = {
    sortBy: 'name',
    sortDir: ascendingSortDir,
    showArchived: false
};

const listTableConfig = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SORT_POST_TAGS: {
            const { sortBy } = payload;
            let newSortOrder = ascendingSortDir;
            if (state.sortBy === sortBy && state.sortDir === ascendingSortDir) {
                newSortOrder = descendingSortDir;
            }
            return Object.assign({}, state, { sortBy, sortDir: newSortOrder });
        }
        case TOGGLE_ARCHIVED_POST_TAGS_FILTER: {
            return Object.assign({}, state, { showArchived: !state.showArchived });
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    archiving: createAsyncStatesReducerForAction(
        () => 'state',
        [POST_TAGS_ARCHIVE_REQUEST, POST_TAGS_FORCE_ARCHIVE_REQUEST],
        [POST_TAGS_ARCHIVE_SUCCESS, POST_TAGS_FORCE_ARCHIVE_SUCCESS, POST_TAGS_ARCHIVE_ERROR, POST_TAGS_FORCE_ARCHIVE_ERROR],
    ),
    unarchiving: createAsyncStatesReducerForAction(
        () => 'state',
        [POST_TAGS_UNARCHIVE_REQUEST],
        [POST_TAGS_UNARCHIVE_SUCCESS, POST_TAGS_UNARCHIVE_ERROR],
    ),
    automationState: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.postTagId,
        [POST_TAG_AUTOMATION_ACTIVATE_REQUEST, POST_TAG_AUTOMATION_DEACTIVATE_REQUEST],
        [POST_TAG_AUTOMATION_ACTIVATE_SUCCESS, POST_TAG_AUTOMATION_DEACTIVATE_SUCCESS],
        [POST_TAG_AUTOMATION_ACTIVATE_ERROR, POST_TAG_AUTOMATION_DEACTIVATE_ERROR]
    ),
    editState: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [POST_TAG_EDIT_REQUEST],
        [POST_TAG_EDIT_SUCCESS],
        [POST_TAG_EDIT_ERROR]
    ),
});

export const postTags = combineReducers({
    byId,
    archivedPostTagIds,
    activePostTagIds,
    listTableConfig,
    asyncStates,
});
