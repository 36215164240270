import { selectCustomColors, selectFavorites } from 'src/selectors/profiles';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import React from 'react';

const withCustomColorAndFavorites = (WrappedChartView) => {
    const ChartViewWithCustomColorAndFavorites = (props) => <WrappedChartView {...props} />;
    ChartViewWithCustomColorAndFavorites.propTypes = {
        customColors: customPropTypes.customColors.isRequired,
        favorites: customPropTypes.favorites.isRequired
    };

    const wrappedChartViewName = WrappedChartView.displayName || WrappedChartView.name || 'Component';
    ChartViewWithCustomColorAndFavorites.displayName = `withCustomColorInterpretation(${wrappedChartViewName})`;

    function mapStateToProps(state) {
        return {
            customColors: selectCustomColors(state),
            favorites: selectFavorites(state)
        };
    }

    return connect(mapStateToProps)(ChartViewWithCustomColorAndFavorites);
};

export default withCustomColorAndFavorites;
