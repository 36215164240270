import React, { Component } from 'react';
import _get from 'lodash/get';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { highchartsCachedMaps } from 'src/components/chartViews/highcharts/configs/highmapsUtils';
import { mapIndex } from 'src/components/chartViews/highcharts/mapIndex';
import PropTypes from 'prop-types';
import VerticallyHorizontallyCentered from 'src/components/loadingIndicators/VerticallyHorizontallyCentered';

const withMapLoading = (WrappedComponent) => {
    class WithMapLoading extends Component {
        constructor(props) {
            super(props);
            const { mapName } = this.props;
            let loading = true;
            let mapObject = null;
            let error = null;
            const mapMetaData = _get(mapIndex, mapName, null);
            // is map data available?
            if (!mapMetaData) {
                // check if map is a valid mapCode
                loading = false;
                error = `Unknown map ${mapName}`;
            } else {
                // check if data is cached
                const mapFileName = mapMetaData.name;
                const mapFromCache = _get(highchartsCachedMaps, mapFileName, false);
                if (mapFromCache) {
                    loading = false;
                    mapObject = mapFromCache;
                }
            }

            this.state = {
                mapMetaData,
                mapObject,
                loading,
                error
            };
        }

        componentDidMount() {
            const { mapObject, error, mapMetaData } = this.state;
            if (mapMetaData && !mapObject && !error) {
                this.getMapData(mapMetaData);
            }
        }

        handleResize(width, height) {
            const { chart } = this;
            if (chart) {
                return chart.handleResize(width, height);
            }
            return false;
        }

        getMapData(mapMetaData) {
            const serverRequest = createServerRequest({ map: mapMetaData.src });
            serverRequest('/map-data/load-map').then((response) => {
                const mapObject = {
                    countryCode: mapMetaData.iso,
                    infoData: mapMetaData,
                    mapData: response.jsonData.map
                };
                highchartsCachedMaps[mapMetaData.name] = mapObject;
                this.setState({
                    mapObject,
                    loading: false
                });
            }).catch(() => {
                this.setState({
                    loading: false,
                    error: 'Error'
                });
            });
        }

        render() {
            const { loading, mapObject, error } = this.state;
            if (loading) {
                return <VerticallyHorizontallyCentered text="Loading map..." />;
            }
            if (error !== null) {
                return <div>Error: {error}</div>;
            }
            return (<WrappedComponent ref={(c) => { this.chart = c; }} {...this.props} mapObject={mapObject} />);
        }
    }

    WithMapLoading.propTypes = {
        mapName: PropTypes.string.isRequired
    };

    return WithMapLoading;
};

export default withMapLoading;
