import _get from 'lodash/get';
import _range from 'lodash/range';
import classnames from 'classnames';
import FixedDataTableWidthHelper from 'src/components/chartViews/fixedDataTable/tableEngine/FixedDataTableWidthHelper';
import React from 'react';
import styles from 'src/stylesheets/table/exportTable.scss';
import TableCell from 'src/components/chartViews/fixedDataTable/TableCell';
import tableCells from 'phpConfig/tableCells.json';

const TableBodyRows = (props) => {
    const {
        columns, rowsCount, sortBy, sortDir, results
    } = props;

    if (rowsCount < 1) {
        return (
            <tr>
                <td className={classnames(styles.noData, styles.centerAlign)} colSpan={columns.length}>Empty results</td>
            </tr>
        );
    }

    return _range(rowsCount).map((index) => {
        const lastRow = (index === rowsCount - 1);
        const columnsToRender = columns.map((column) => {
            const columnType = _get(column, 'type', 'string');
            const breakWordInExport = _get(tableCells, [columnType, 'export', 'wordWrap'], false);
            const columnsSortDir = column.id === sortBy ? sortDir : null;
            const align = FixedDataTableWidthHelper.getAlignment(column.type);
            const className = classnames(
                styles.tableCell,
                styles.tableBodyCell,
                styles[`${align}Align`],
                {
                    [styles.lastTableRow]: lastRow,
                    [styles.wordwrap]: breakWordInExport
                }
            );
            return (
                <TableCell
                  data={results}
                  sortDir={columnsSortDir}
                  column={column}
                  columnKey={column.id}
                  rowIndex={index}
                  className={className}
                  key={`td-${column.id}-${index}`}
                />
            );
        });

        return (
            <tr key={`tr-${index}`}>
                {columnsToRender}
            </tr>
        );
    });
};

export default TableBodyRows;
