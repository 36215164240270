import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import continuousBubbleChart from './highcharts/configs/ContinuousBubbleChart';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';

const ContinuousBubbleChartView = highchartsChartViewFactory(continuousBubbleChart);

ContinuousBubbleChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    dateFormat: PropTypes.string.isRequired,
    percent: PropTypes.bool,
    weekDefinition: PropTypes.oneOf(['SuSa', 'MoSu']).isRequired,
    customColors: customPropTypes.customColors.isRequired,
    isSampleMetric: PropTypes.bool
};

ContinuousBubbleChartView.defaultProps = {
    isSampleMetric: false
};

export default ContinuousBubbleChartView;
