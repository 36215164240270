import {
    DistinctColorArray,
    getColor,
    getDistinctColorPalette
} from 'src/components/chartViews/highcharts/customColorUtils';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { getColorPalette } from 'src/components/chartViews/highcharts/Highcharts';
import {
    getAxisType,
    getDateOptionsByIntervalType,
    getIntervalType,
    getStartOfWeek
} from './utils';

const twoDimChart = (props, Highcharts, formatNumber) => {
    const customColorsInUse = new DistinctColorArray();
    const {
        data,
        metricConfig,
        metricParameters,
        selectedDate,
        dateFormat,
        defaultSeriesType,
        width,
        height,
        stacked,
        percent,
        context,
        customColors,
        favorites,
        weekDefinition,
        detectIsolatedPoints,
        numberFormat,
        isSampleMetric
    } = props;
    const defaultColorPalette = getColorPalette(isSampleMetric);
    const interval = getIntervalType(selectedDate, metricParameters);
    const dateOptions = getDateOptionsByIntervalType(interval, dateFormat, weekDefinition);

    // detect type of x axis
    const dim1AxisType = getAxisType('dim1', data);
    const xAxisType = getAxisType('dim2', data);

    // now start building series data
    const chartData = [];

    // check if there is special information about what series should be mapped to what axes
    const tmpAxesConfig = _get(metricConfig, 'metaData.dim1.axes', []);
    const axesConfig = [];
    for (let i = 0, iLen = tmpAxesConfig.length; i < iLen; i++) {
        axesConfig.push(Object.assign({}, tmpAxesConfig[i], { id: i }));
    }

    const multipleAxes = (axesConfig.length > 0);
    const pages = data.infoData;
    let singlePageTemp = {};

    // add one series per profile
    for (let i = 0, iLen = pages.length; i < iLen; i++) {
        let yAxis = 0;
        for (let j = 0, jLen = axesConfig.length; j < jLen; j++) {
            if (axesConfig[j].labels.indexOf(pages[i].id) !== -1) {
                yAxis = axesConfig[j].id;
            }
        }

        const temp = {
            name: pages[i].name,
            profileId: pages[i].id,
            data: data.hcChartData[`data${pages[i].id}`] || [],
            yAxis: (dim1AxisType === 'profiles') ? 0 : yAxis
        };

        if (_has(customColors, pages[i].id)) {
            const customColor = getColor(customColors[pages[i].id]);
            temp.color = customColor.getAsHex();
            customColorsInUse.push(customColor);
        }

        if (_get(favorites, pages[i].id) === true) {
            temp.name = `<b>${temp.name}</b>`;
            temp.lineWidth = 5;
        }
        // if this is an isolated data point, enable marker in order to make it visible
        // TODO: detect isolated points, for now this logic only covers the scenario where just
        // one point exists for a given page in total, anyhow, as we have further logic to
        // remove inner data points with value "null", this is somehow complicated to solve,
        // as there is not one value per interval guaranteed
        if (detectIsolatedPoints === true) {
            let pointsWithNonNullValues = 0;
            for (let j = 0, jLen = temp.data.length; j < jLen; j++) {
                if (temp.data[j][1] !== null) {
                    pointsWithNonNullValues += 1;
                }
            }
            if (pointsWithNonNullValues === 1) {
                temp.marker = {
                    enabled: true,
                    radius: 3,
                    symbol: 'circle'
                };
            }
        }

        // regression lines and moving averages are only possible if there is only one series
        if (pages.length === 1 && _has(metricConfig, 'metaData.dim1.regressionLine')) {
            temp.id = 'regressionLine';
        }

        singlePageTemp = temp;
        chartData.push(temp);
    }

    if (pages.length === 1 && _has(metricConfig, 'metaData.dim1.regressionLine')) {
        if (metricConfig.metaData.dim1.regressionLine === true) {
            const linearRegression = {
                name: _get(metricConfig, 'metaData.dim1.regressionName', 'Linear Trendline'),
                linkedTo: 'regressionLine',
                showInLegend: true,
                type: 'trendline',
                algorithm: 'linear'
            };
            chartData.push(linearRegression);
        } else if (metricConfig.metaData.dim1.regressionLine === 'sma') {
            const sma = {
                name: _get(metricConfig, 'metaData.dim1.regressionName', 'Simple Moving Average'),
                periods: _get(
                    metricConfig,
                    'metaData.dim1.periods',
                    (singlePageTemp.data.length > 2 ? Math.round(singlePageTemp.data.length * 0.2) : 1)
                ),
                linkedTo: 'regressionLine',
                showInLegend: true,
                type: 'trendline',
                algorithm: 'SMA'
            };
            chartData.push(sma);
        } else if (metricConfig.metaData.dim1.regressionLine === 'ema') {
            const ema = {
                name: _get(metricConfig, 'metaData.dim1.regressionName', 'Exponential Moving Average'),
                periods: _get(
                    metricConfig,
                    'metaData.dim1.periods',
                    (singlePageTemp.data.length > 2 ? Math.round(singlePageTemp.data.length * 0.2) : 2)
                ),
                linkedTo: 'regressionLine',
                showInLegend: true,
                type: 'trendline',
                algorithm: 'EMA'
            };
            chartData.push(ema);
        }
    }

    // build x axis and marker config based on type
    const xAxis = {
        type: (xAxisType === 'datetime') ? xAxisType : 'category'
    };

    const marker = {
        states: {
            hover: {
                enabled: true,
                radius: 5
            }
        }
    };

    if (xAxisType === 'datetime') {
        xAxis.dateTimeLabelFormats = dateOptions.dateFormat;
        xAxis.minTickInterval = dateOptions.minTickInterval;
        marker.enabled = false;
        xAxis.startOfWeek = getStartOfWeek(weekDefinition);
    } else {
        marker.enabled = true;
        marker.symbol = 'square';
        marker.radius = 4;
    }

    // for the non profiles series, set the layout for the all y axes
    let yAxes = [];
    if (multipleAxes) {
        let counter = 0;
        for (let i = 0, iLen = axesConfig.length; i < iLen; i += 1) {
            const labelText = [];

            for (let j = 0, jLen = axesConfig[i].labels.length; j < jLen; j += 1) {
                for (let k = 0, kLen = data.infoData.length; k < kLen; k += 1) {
                    if (axesConfig[i].labels[j] === data.infoData[k].id) {
                        counter = k;
                    }
                }
                labelText.push(`<b style='color: ${defaultColorPalette[counter]};'>${axesConfig[i].labels[j]}</b>`);
            }

            const title = labelText.join(', ');

            yAxes.push({
                title: {
                    text: title,
                    useHTML: true,
                    enabled: true
                },
                startOnTick: false,
                endOnTick: true,
                gridLineWidth: i === 0 ? 1 : 0,
                opposite: i !== 0
            });
        }
    } else {
        yAxes = [{
            title: {
                text: '',
                enabled: false
            },
            startOnTick: false,
            endOnTick: true
        }];
    }

    let stacking = null;
    if (stacked) {
        stacking = percent ? 'percent' : 'normal';
    }
    return {
        chart: {
            defaultSeriesType,
            width,
            height
        },
        xAxis,
        yAxis: yAxes,
        colors: getDistinctColorPalette(customColorsInUse, defaultColorPalette),
        tooltip: {
            formatter() {
                const calculateTotal = (point) => {
                    let sum = 0;
                    for (let i = 0; i <= point.point.index; i++) {
                        sum += point.series.data[i].y;
                    }
                    return sum;
                };
                const renderPointAsPercentage = (point) => `<b>${point.series.name}</b><br />${formatNumber(numberFormat, (point.y / point.total) * 100)}%<br />${formatNumber(numberFormat, point.y, 2)}`;

                const renderPoint = (point) => `<b>${point.series.name}</b><br />${(xAxisType === 'datetime' ? Highcharts.dateFormat(dateOptions.dateFormatToolTip, point.x) : point.key)}<br />${formatNumber(numberFormat, point.y, 4)}`;
                let tooltip;
                if (typeof percent !== 'undefined' && percent === true) {
                    tooltip = renderPointAsPercentage(this);
                } else {
                    tooltip = renderPoint(this);
                }
                if (defaultSeriesType === 'waterfall') {
                    tooltip += `<br /><br />Total: ${formatNumber(numberFormat, calculateTotal(this))}`;
                }
                return tooltip;
            }
        },
        plotOptions: {
            line: {
                marker,
                enableMouseTracking: context !== 'export'
            },
            column: {
                marker,
                stacking
            },
            bar: {
                marker,
                stacking
            },
            area: {
                marker,
                stacking
            }
        },
        series: chartData
    };
};

export default twoDimChart;
