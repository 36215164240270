import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';
import pieChart from './highcharts/configs/PieChart';

const PieChartView = highchartsChartViewFactory(pieChart);

PieChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    customColors: customPropTypes.customColors.isRequired,
    isSampleMetric: PropTypes.bool
};

PieChartView.defaultProps = {
    isSampleMetric: false
};

export default PieChartView;
