import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import funnelChart from './highcharts/configs/FunnelChart';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';

const FunnelChartView = highchartsChartViewFactory(funnelChart);

FunnelChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

export default FunnelChartView;
