import React from 'react';
import SummaryLineCell from 'src/components/chartViews/fixedDataTable/tableEngine/SummaryLineCell';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/table/table.scss';

const SummaryLine = (props) => {
    const {
        summaryLineResults,
        columns,
        results
    } = props;

    const columnsToRender = columns.map((column) => (
        <SummaryLineCell className={styles.summaryLineCellExport} key={column.id} column={column} summaryLine={summaryLineResults} results={results} />
    ));

    return (
        <tr
          key="tr-summaryLine"
        >
            {columnsToRender}
        </tr>
    );
};

SummaryLine.propTypes = {
    summaryLineResults: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(customPropTypes.tableCellColumn).isRequired,
    results: PropTypes.object.isRequired,
};

export default SummaryLine;
