import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/chartViews/topContent/topContentView.scss';
import PropTypes from 'prop-types';
import TopContentSlider from 'src/components/chartViews/topContent/TopContentSlider';
import ExportTopContentView from 'src/components/chartViews/topContent/ExportTopContentView';

class TopContentView extends Component {
    // eslint-disable-next-line class-methods-use-this
    handleResize() {
        return false;
    }

    render() {
        const {
            numberFormat, data, isExport, width
        } = this.props;
        const { results } = data;
        return (
            <div className={styles.wrapper}>
                {
                    results.length === 0 && (
                        <div className={styles.empty}>
                            No data to display
                        </div>
                    )
                }
                {
                    (results.length > 0 && !isExport) && (
                        <TopContentSlider
                          width={width}
                          results={results}
                          numberFormat={numberFormat}
                        />
                    )
                }
                {
                    (results.length > 0 && isExport) && (
                        <ExportTopContentView results={results} numberFormat={numberFormat} />
                    )
                }
            </div>
        );
    }
}

TopContentView.propTypes = {
    data: customPropTypes.data.isRequired,
    isExport: PropTypes.bool.isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired,
    width: PropTypes.number
};

TopContentView.defaultProps = {
    width: null,
};

export default TopContentView;
