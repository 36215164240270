import {
    DASHBOARD_ADD_SUCCESS,
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS,
    DASHBOARD_CLONE_ERROR,
    DASHBOARD_CLONE_REQUEST,
    DASHBOARD_CLONE_SUCCESS,
    DASHBOARD_EDIT_SUCCESS,
    DASHBOARD_METRIC_IDS_CLEANUP_AFTER_CUSTOM_METRIC_DELETE
} from 'src/actions/dashboard';
import {
    DASHBOARD_METRIC_CLONE_SUCCESS,
    DASHBOARD_METRIC_MOVE_SUCCESS,
    DASHBOARD_METRICS_ADD_SUCCESS,
    DASHBOARD_METRICS_DELETE_SUCCESS,
    DASHBOARD_METRICS_UPDATE_LAYOUT_ERROR,
    DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST,
    DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS
} from 'src/actions/dashboardMetrics';
import { FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS, FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS } from 'src/actions/folders';
import _difference from 'lodash/difference';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import _sortBy from 'lodash/sortBy';
import { combineReducers } from 'redux';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import {
    createAsyncStatesReducerForAction,
} from 'src/reducers/utils';

function updateDashboardMetricsList(state, dashboardMetricId) {
    return Object.assign({}, state, { dashboardMetricIds: _filter(state.dashboardMetricIds, (value) => value !== dashboardMetricId) });
}

function addToDashboardMetricsList(state, newId) {
    return Object.assign({}, state, { dashboardMetricIds: [...state.dashboardMetricIds, newId] });
}

export function byId(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboards } = payload.dashboards;
            const parsedDashboards = {};
            Object.keys(dashboards).forEach((id) => {
                parsedDashboards[id] = dashboards[id];
            });
            return Object.assign({}, state, parsedDashboards);
        }
        case DASHBOARD_CLONE_SUCCESS:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
        case DASHBOARD_ADD_SUCCESS: {
            const { dashboard } = payload;
            return Object.assign({}, state, { [dashboard.id]: dashboard });
        }
        case DASHBOARD_METRICS_ADD_SUCCESS:
        case DASHBOARD_METRIC_CLONE_SUCCESS: {
            const { entity } = payload.dashboardMetric;
            return Object.assign({}, state, {
                [entity.dashboardId]: addToDashboardMetricsList(state[entity.dashboardId], entity.id)
            });
        }
        case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
            const { dashboardIds } = payload;
            let newState = Object.assign({}, state);
            dashboardIds.forEach((dashboardId) => {
                newState = _omit(newState, dashboardId);
            });
            return newState;
        }
        case DASHBOARD_METRICS_DELETE_SUCCESS: {
            const { dashboardId, dashboardMetricId } = payload;
            return Object.assign({}, state, {
                [dashboardId]: updateDashboardMetricsList(state[dashboardId], dashboardMetricId)
            });
        }
        case DASHBOARD_METRIC_MOVE_SUCCESS: {
            const { dashboardMetric, sourceDashboardId, removedDashboardMetricId } = payload;
            const cleanedState = Object.assign({}, state, {
                [sourceDashboardId]: updateDashboardMetricsList(state[sourceDashboardId], removedDashboardMetricId)
            });
            const { entity } = dashboardMetric;
            return Object.assign({}, cleanedState, {
                [entity.dashboardId]: addToDashboardMetricsList(state[entity.dashboardId], entity.id)
            });
        }
        case DASHBOARD_EDIT_SUCCESS: {
            const { dashboard } = payload;
            return Object.assign({}, state, { [dashboard.id]: dashboard });
        }
        case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
            const { dashboardsResponses } = payload;
            if (dashboardsResponses.length > 0) {
                const newState = Object.assign({}, state);
                dashboardsResponses.forEach((dashboardResponse) => {
                    const { dashboard } = dashboardResponse.movedDashboard;
                    Object.assign(newState, { [dashboard.id]: dashboard });
                });
                return newState;
            }
            return state;
        }
        case DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS: {
            const { dashboardId, orderedDashboardWidgetIds } = payload;
            return Object.assign({}, state, {
                [dashboardId]: Object.assign({}, state[dashboardId], { dashboardMetricIds: orderedDashboardWidgetIds })
            });
        }
        case DASHBOARD_METRIC_IDS_CLEANUP_AFTER_CUSTOM_METRIC_DELETE: {
            const { dashboards } = payload;
            const updatedDashboards = {};
            dashboards.forEach((deletedDashboardMetricIds, dashboardId) => {
                if (state[dashboardId]) {
                    const existingDashboardMetricIds = Object.values(Object.assign({}, state[dashboardId].dashboardMetricIds));
                    const updatedDashboardMetricIds = _difference(existingDashboardMetricIds, deletedDashboardMetricIds);
                    updatedDashboards[dashboardId] = Object.assign({}, state[dashboardId], { dashboardMetricIds: updatedDashboardMetricIds });
                }
            });
            return Object.assign({}, state, updatedDashboards);
        }
        default:
            return state;
    }
}

function allIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboards } = payload.dashboards;
            const sorted = _sortBy(dashboards, 'pos');
            const sortedIds = sorted.map((dashboard) => dashboard.id);
            return [...state, ...sortedIds];
        }
        case DASHBOARD_CLONE_SUCCESS:
        case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
        case DASHBOARD_ADD_SUCCESS: {
            const { dashboard } = payload;
            return [...state, dashboard.id];
        }
        case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
            const { dashboardIds } = payload;
            const newState = Object.values(Object.assign({}, state));
            dashboardIds.forEach((dashboardId) => {
                newState.splice(newState.indexOf(dashboardId), 1);
            });
            return newState;
        }
        default:
            return state;
    }
}

const asyncStatesByAction = combineReducers({
    gridLoading: createAsyncStatesReducerForAction(
        (payload) => payload.dashboardId,
        [DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST],
        [DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS, DASHBOARD_METRICS_UPDATE_LAYOUT_ERROR]
    ),
    clone: createAsyncStatesReducerForAction(
        (payload) => payload.cloneDashboardId,
        [DASHBOARD_CLONE_REQUEST],
        [DASHBOARD_CLONE_SUCCESS, DASHBOARD_CLONE_ERROR]
    )
});

export const dashboards = combineReducers({
    allIds,
    byId,
    asyncStatesByAction
});
