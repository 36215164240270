import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import gaugeChart from './highcharts/configs/GaugeChart';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';

const GaugeChartView = highchartsChartViewFactory(gaugeChart);

GaugeChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

export default GaugeChartView;
