import _has from 'lodash/has';
import { getRendererByType } from 'src/components/chartViews/renderers';

const gaugeChart = (props) => {
    const {
        data, metricConfig, width, height, numberFormat
    } = props;
    const value = data.val;
    const { metaData } = metricConfig;
    const { valueColumn } = metaData;
    let min;
    let max;

    if (!_has(metaData, 'min') || !_has(metaData, 'max')) {
        min = Math.min(0, value);
        max = 10 ** Math.ceil(Math.log10(value));
    } else {
        ({ min } = metaData);
        ({ max } = metaData);
    }
    const seriesData = [{
        name: data.label,
        data: [parseFloat(value)], // here accepts only double value
        tooltip: {
            valueSuffix: data.label
        }
    }];

    let stops = [];
    if (_has(metaData, 'colorStops')) {
        for (let i = 0, iLen = metaData.colorStops.length; i < iLen; i++) {
            stops.push([metaData.colorStops[i].stop, metaData.colorStops[i].colorCode]);
        }
    } else {
        stops = [
            [0.0, '#DF5353'], // red
            [0.5, '#DDDF0D'], // yellow
            [1.0, '#55BF3B'] // green
        ];
    }

    return {
        chart: {
            type: 'solidgauge',
            width,
            height
        },
        pane: {
            center: ['50%', '65%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: '#ECECEC',
                innerRadius: '70%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        plotOptions: {
            solidgauge: {
                innerRadius: '70%',
                dataLabels: {
                    y: -2,
                    borderWidth: 0,
                    useHTML: true,
                    padding: 0,
                    allowOverlap: true,
                    formatter() {
                        return `<div style='text-align: center'>
                            <div style='font-size:24px; color:#484848; line-height:1;'>${getRendererByType(valueColumn.type)(value, numberFormat)}</div>
                            <div style='font-size:15px; color:#a4a4a4; line-height:1; font-weight:normal; padding-top: 8px;'>${data.label}</div>
                        </div>`;
                    }
                }
            }
        },
        tooltip: {
            enabled: false
        },
        yAxis: [{
            min,
            max,
            stops,
            lineWidth: 0,
            minorTickInterval: null,
            tickPixelInterval: 400,
            tickWidth: 0,
            tickPositions: [min, max],
            labels: {
                y: 15,
                useHTML: true,
                style: {
                    fontSize: '13px',
                    color: '#a4a4a4'
                },
                formatter() {
                    return getRendererByType(valueColumn.type)(this.value, numberFormat);
                }
            }
        }],
        series: seriesData
    };
};

export default gaugeChart;
