import React from 'react';
import PropTypes from 'prop-types';
import AdCampaignFilterSummary from 'src/components/filter/AdCampaignFilterSummary';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import classnames from 'classnames';
import styles from 'src/stylesheets/filter.scss';
import DateFilterSummary from 'src/components/filter/DateFilterSummary';
import ProfileFilterSummary from 'src/components/filter/ProfileFilterSummary';
import CombinedPostTextFilterSummary from 'src/components/filter/CombinedPostTextFilterSummary';
import PostTagFilterSummary from 'src/components/filter/PostTagFilterSummary';
import AbsoluteLimitParameterSummary from 'src/components/filter/AbsoluteLimitParameterSummary';

const orderedValidFilterTypeIds = [
    'profile',
    'date',
    'postText',
    'postTextExclude',
    'postTag',
    'adCampaign'
];

const getFilterPosition = (typeId) => orderedValidFilterTypeIds.indexOf(typeId);

const getFilterOrNull = (filterTypeId, fullyApplied, partiallyApplied, notApplied) => {
    const fullyAppliedFilter = _get(fullyApplied, filterTypeId, null);
    const partiallyAppliedFilter = _get(partiallyApplied, filterTypeId, null);
    const notAppliedFilter = _get(notApplied, filterTypeId, null);

    if (fullyAppliedFilter !== null) {
        return { id: filterTypeId, state: 'fullyApplied', settings: fullyAppliedFilter };
    }
    if (partiallyAppliedFilter !== null) {
        return { id: filterTypeId, state: 'partiallyApplied', settings: partiallyAppliedFilter };
    }
    if (notAppliedFilter !== null) {
        return { id: filterTypeId, state: 'notApplied', settings: notAppliedFilter };
    }

    return null;
};

const getFiltersByTypeId = (fullyApplied, partiallyApplied, notApplied) => {
    const filtersByTypeId = {};
    orderedValidFilterTypeIds.forEach((filterTypeId) => {
        const curFilter = getFilterOrNull(filterTypeId, fullyApplied, partiallyApplied, notApplied);
        if (curFilter !== null) {
            filtersByTypeId[filterTypeId] = curFilter;
        }
    });

    return filtersByTypeId;
};

const renderSingleFilterItem = (id, state, input, filtersWithCustomBinding) => {
    const hasCustomBinding = filtersWithCustomBinding.indexOf(id) !== -1;

    switch (id) {
        case 'profile':
            return <ProfileFilterSummary state={state} input={input} hasCustomBinding={hasCustomBinding} />;
        case 'date':
            return <DateFilterSummary state={state} input={input} hasCustomBinding={hasCustomBinding} />;
        case 'postTag':
            return <PostTagFilterSummary state={state} input={input} hasCustomBinding={hasCustomBinding} />;
        case 'adCampaign':
            return <AdCampaignFilterSummary input={input} state={state} hasCustomBinding={hasCustomBinding} />;
        default:
            return <div className={styles.textWrapper}>Unknown filter</div>;
    }
};

const renderFilterListItems = (filtersByTypeId, filtersWithCustomBinding) => {
    const filterListItems = [];

    // Post text filters are rendered by one combined filter component
    const postTextIncludeAndExcludeFilterWithState = _pick(filtersByTypeId, ['postText', 'postTextExclude']);
    const hasActiveIncludeOrExportFilter = _has(postTextIncludeAndExcludeFilterWithState, 'postText')
        || _has(postTextIncludeAndExcludeFilterWithState, 'postTextExclude');

    if (hasActiveIncludeOrExportFilter) {
        // We expect both include and export filter to be used together, so far simplicity reason
        // we just derive the combined state (fully applied, etc.) from the first set filter.
        const state = _has(postTextIncludeAndExcludeFilterWithState, 'postText')
            ? postTextIncludeAndExcludeFilterWithState.postText.state
            : postTextIncludeAndExcludeFilterWithState.postTextExclude.state;

        // Contruct custom prop type "postTextIncludeAndExcludeFilter" based on both states
        const postTextIncludeAndExcludeFilter = {};
        if (_has(postTextIncludeAndExcludeFilterWithState, 'postText.state')) {
            postTextIncludeAndExcludeFilter.postText = postTextIncludeAndExcludeFilterWithState.postText.settings;
        }
        if (_has(postTextIncludeAndExcludeFilterWithState, 'postTextExclude.state')) {
            postTextIncludeAndExcludeFilter.postTextExclude = postTextIncludeAndExcludeFilterWithState.postTextExclude.settings;
        }

        const hasCustomBinding = filtersWithCustomBinding.indexOf('postText') !== -1
            || filtersWithCustomBinding.indexOf('postTextExclude') !== -1;

        filterListItems[getFilterPosition('postText')] = (
            <li key="filter-postText" className={styles.listItemWrapper}>
                <CombinedPostTextFilterSummary
                  state={state}
                  input={postTextIncludeAndExcludeFilter}
                  hasCustomBinding={hasCustomBinding}
                />
            </li>
        );
    }

    // Filters that map 1:1 for rendering
    const singleFiltersByTypeId = _omit(filtersByTypeId, ['postText', 'postTextExclude']);
    Object.keys(singleFiltersByTypeId).forEach((typeId) => {
        const singleFilter = singleFiltersByTypeId[typeId];
        filterListItems[getFilterPosition(singleFilter.id)] = (
            <li key={`filter-${singleFilter.id}`} className={styles.listItemWrapper}>
                {
                    renderSingleFilterItem(
                        singleFilter.id,
                        singleFilter.state,
                        singleFilter.settings,
                        filtersWithCustomBinding
                    )
                }
            </li>
        );
    });

    return filterListItems;
};

const RequestInfoSummary = (props) => {
    const { requestInfo, filtersWithCustomBinding, layout } = props;
    const { filters, parameters } = requestInfo;

    const {
        fullyApplied, partiallyApplied, notApplied
    } = filters;

    const filtersByTypeId = getFiltersByTypeId(fullyApplied, partiallyApplied, notApplied);
    const filterListItems = renderFilterListItems(filtersByTypeId, filtersWithCustomBinding);

    return (
        <div
          className={classnames(
              styles.listWrapper,
              {
                  [styles.small]: (layout === 'small'),
                  [styles.big]: (layout === 'big')
              }
          )}
        >
            <ul className={styles.list}>
                {filterListItems}
                {
                    parameters.absoluteLimit !== null && parseInt(parameters.absoluteLimit, 10) > 0 && (
                        <li className={styles.listItemWrapper} key="parameters-absoluteLimit">
                            <AbsoluteLimitParameterSummary limit={parameters.absoluteLimit} />
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

RequestInfoSummary.propTypes = {
    requestInfo: customPropTypes.requestInfo.isRequired,
    filtersWithCustomBinding: PropTypes.arrayOf(PropTypes.string),
    layout: PropTypes.oneOf(['small', 'big'])
};

RequestInfoSummary.defaultProps = {
    filtersWithCustomBinding: [],
    layout: 'small'
};

export default RequestInfoSummary;
