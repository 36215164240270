import React, { Component } from 'react';
import TopContentView from 'src/components/chartViews/topContent/TopContentView';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import classnames from 'classnames';
import { getMapFromMetric } from 'src/components/chartViews/highcharts/configs/highmapsUtils';
import PropTypes from 'prop-types';
import { shallowCompare } from 'src/utils/shallowEqual';
import styles from 'src/stylesheets/chartView.scss';
import withCustomColorAndFavorites from 'src/components/chartViews/withCustomColorAndFavorites';
import withMapLoading from 'src/components/chartViews/highcharts/withMapLoading';
import BubbleChartView from 'src/components/chartViews/BubbleChartView';
import BubbleMapChartView from 'src/components/chartViews/BubbleMapChartView';
import ContinuousBubbleChartView from 'src/components/chartViews/ContinuousBubbleChartView';
import FunnelChartView from 'src/components/chartViews/FunnelChartView';
import GaugeChartView from 'src/components/chartViews/GaugeChartView';
import MapChartView from 'src/components/chartViews/MapChartView';
import PieChartView from 'src/components/chartViews/PieChartView';
import PyramidChartView from 'src/components/chartViews/PyramidChartView';
import RadarChartView from 'src/components/chartViews/RadarChartView';
import ScatterChartView from 'src/components/chartViews/ScatterChartView';
import TableView from 'src/components/chartViews/TableView';
import TwoDimChartView from 'src/components/chartViews/TwoDimChartView';
import BigNumberView from 'src/components/chartViews/bigNumber/BigNumberView';

const BubbleMapChartViewWithMapLoading = withMapLoading(BubbleMapChartView);
const MapChartViewWithMapLoading = withMapLoading(MapChartView);

class ChartView extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        // If nothing changed, don't update
        if (!shallowCompare(this, nextProps, nextState)) {
            return false;
        }
        const { props } = this;
        // If only width or height changed, don't update, cause we trigger the API call instead
        if ((props.width !== nextProps.width || props.height !== nextProps.height)
            && (props.data === nextProps.data && props.metric === nextProps.metric)) {
            return !this.chartView.handleResize(nextProps.width, nextProps.height);
        }
        return true;
    }

    render() {
        const {
            data,
            metric,
            width,
            height,
            selectedDate,
            selectedProfilesOrGroups,
            accountOptions,
            settings,
            customColors,
            favorites,
            isExport,
            isSampleMetric,
            noFlex
        } = this.props;
        const type = _get(settings, 'visualization.type', metric.visualization.type);
        const chartProps = {
            data,
            metricConfig: metric.config,
            metricParameters: metric.parameters,
            width,
            height,
            context: 'dashboard',
            ref: (c) => { this.chartView = c; },
            className: classnames(styles.chart, { [styles.noFlex]: noFlex }),
            selectedDate,
            dateFormat: accountOptions.dateFormat,
            weekDefinition: accountOptions.weekDefinition,
            numberFormat: accountOptions.numberFormat,
            customColors,
            favorites,
            metricId: metric.id
        };
        switch (type) {
            case 'lineChart':
                return <TwoDimChartView detectIsolatedPoints defaultSeriesType="line" {...chartProps} />;
            case 'columnChart':
                return <TwoDimChartView defaultSeriesType="column" {...chartProps} />;
            case 'stackedColumnChart':
                return <TwoDimChartView defaultSeriesType="column" {...chartProps} stacked />;
            case 'stackedColumnPercentChart':
                return <TwoDimChartView defaultSeriesType="column" {...chartProps} stacked percent />;
            case 'areaChart':
                return <TwoDimChartView defaultSeriesType="area" {...chartProps} />;
            case 'stackedAreaChart':
                return <TwoDimChartView defaultSeriesType="area" {...chartProps} stacked />;
            case 'stackedAreaPercentChart':
                return <TwoDimChartView defaultSeriesType="area" {...chartProps} stacked percent />;
            case 'barChart':
                return <TwoDimChartView defaultSeriesType="bar" {...chartProps} />;
            case 'stackedBarChart':
                return <TwoDimChartView defaultSeriesType="bar" {...chartProps} stacked />;
            case 'stackedBarPercentChart':
                return <TwoDimChartView defaultSeriesType="bar" {...chartProps} stacked percent />;
            case 'waterfallChart':
                return <TwoDimChartView defaultSeriesType="waterfall" {...chartProps} />;
            case 'radarChart':
                return <RadarChartView {...chartProps} />;
            case 'funnelChart':
                return <FunnelChartView {...chartProps} />;
            case 'pieChart':
                return <PieChartView {...chartProps} />;
            case 'pyramidChart':
                return <PyramidChartView {...chartProps} />;
            case 'continuousBubbleChart':
                return <ContinuousBubbleChartView {...chartProps} />;
            case 'gaugeChart':
                return <GaugeChartView {...chartProps} />;
            case 'bubbleChart':
                return <BubbleChartView {...chartProps} />;
            case 'scatterChart':
                return <ScatterChartView {...chartProps} />;
            case 'table':
                return (
                    <TableView
                      {...chartProps}
                      metric={metric}
                      selectedProfilesOrGroups={selectedProfilesOrGroups}
                      settings={settings}
                      isExport={isExport}
                      isSampleMetric={isSampleMetric}
                    />
                );
            case 'mapChart':
                return <MapChartViewWithMapLoading {...chartProps} mapName={getMapFromMetric(metric)} />;
            case 'bubbleMapChart':
                return <BubbleMapChartViewWithMapLoading {...chartProps} mapName={getMapFromMetric(metric)} />;
            case 'bigNumber':
                return <BigNumberView {...chartProps} />;
            case 'topContent':
                return <TopContentView {...chartProps} isExport={isExport} />;
            default:
                throw new Error(`Unsupported visualizationType ${type}`);
        }
    }
}

ChartView.propTypes = {
    accountOptions: customPropTypes.accountOptions.isRequired,
    data: customPropTypes.data.isRequired,
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    settings: customPropTypes.dashboardMetricSettings,
    customColors: customPropTypes.customColors.isRequired,
    favorites: PropTypes.objectOf(PropTypes.bool).isRequired,
    isExport: PropTypes.bool,
    isSampleMetric: PropTypes.bool,
    noFlex: PropTypes.bool
};

ChartView.defaultProps = {
    width: 0,
    height: 0,
    isExport: false,
    isSampleMetric: false,
    noFlex: false
};

export default withCustomColorAndFavorites(ChartView);
