import React, { Component } from 'react';
import styles from 'src/stylesheets/chartViews/bigNumber/bigNumberView.scss';
import * as customPropTypes from 'src/customPropTypes';
import SingleValue from 'src/components/chartViews/SingleValue';
import ChangeValue from 'src/components/chartViews/ChangeValue';
import ScaleText from 'src/components/scaleText/ScaleText';

class BigNumberView extends Component {
    // eslint-disable-next-line class-methods-use-this
    handleResize() {
        return false;
    }

    render() {
        const { data, metricConfig, numberFormat } = this.props;
        const { metaData } = metricConfig;
        const { valueColumn, changeColumn } = metaData;
        const {
            label,
            singleValue,
            changeValue,
            changePercentValue
        } = data;
        return (
            <div className={styles.flex}>
                <ScaleText minFontSize={10} maxFontSize={100}>
                    <div className={styles.wrapper}>
                        <div className={styles.content}>
                            <div className={styles.label}>{label}</div>
                            <div className={styles.singleValue}>
                                <SingleValue
                                  value={singleValue}
                                  type={valueColumn.type}
                                  numberFormat={numberFormat}
                                />
                            </div>
                            {
                                changeValue !== null
                                && (
                                    <div className={styles.change}>
                                        <ChangeValue
                                          big
                                          value={changeValue}
                                          type={changeColumn.type}
                                          numberFormat={numberFormat}
                                          changePercentValue={changePercentValue}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </ScaleText>
            </div>
        );
    }
}

BigNumberView.propTypes = {
    metricConfig: customPropTypes.metricConfig.isRequired,
    data: customPropTypes.bigNumberData.isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired
};

export default BigNumberView;
