import { DistinctColorArray, getColor, getDistinctColorPalette } from 'src/components/chartViews/highcharts/customColorUtils';
import _has from 'lodash/has';
import { getColorPalette } from 'src/components/chartViews/highcharts/Highcharts';
import { getStartOfWeek } from './utils';

const radarChart = (props, Highcharts, formatNumber) => {
    const customColorsInUse = new DistinctColorArray();
    const {
        data,
        width,
        height,
        context,
        customColors,
        weekDefinition,
        numberFormat,
        isSampleMetric
    } = props;
    const chartData = [];
    const pages = data.infoData;
    const defaultColorPalette = getColorPalette(isSampleMetric);
    for (let i = 0, iLen = pages.length; i < iLen; i += 1) {
        const tmpData = [];
        for (let j = 0, jLen = data.hcChartData[`data${pages[i].id}`].data.length; j < jLen; j += 1) {
            tmpData.push(data.hcChartData[`data${pages[i].id}`].data[j]);
        }
        const temp = {
            name: data.hcChartData[`data${pages[i].id}`].name,
            data: tmpData,
            lineWidth: 2
        };
        if (_has(customColors, pages[i].id)) {
            const customColor = getColor(customColors[pages[i].id]);
            temp.color = customColor.getAsHex();
            customColorsInUse.push(customColor);
        }
        chartData.push(temp);
    }

    return {
        chart: {
            polar: true,
            type: 'line',
            width,
            height
        },
        colors: getDistinctColorPalette(customColorsInUse, defaultColorPalette),
        pane: {
            center: ['50%', '50%'],
            size: '85%',
            startAngle: 0,
            endAngle: 360
        },
        xAxis: {
            type: 'category',
            tickmarkPlacement: 'on',
            gridLineWidth: 1,
            gridLineColor: 'lightgrey',
            lineWidth: 0,
            labels: {
                enabled: true
            },
            startOfWeek: getStartOfWeek(weekDefinition)
        },
        yAxis: [{
            gridLineWidth: 1,
            min: 0,
            max: 100,
            gridLineInterpolation: 'polygon',
            labels: {
                enabled: false
            },
            startOfWeek: getStartOfWeek(weekDefinition)
        }],
        tooltip: {
            formatter() {
                const isResponseRate = this.point.name.match(/Response/);
                if (isResponseRate === null) {
                    return `${this.point.name}<br/><b>${this.series.name}:</b> ${formatNumber(numberFormat, this.point.absVal, 4)}`;
                }
                return `${this.point.name}<br/><b>${this.series.name}:</b> ${formatNumber(numberFormat, this.point.absVal, 1)}%`;
            }
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true,
                            radius: 5
                        }
                    }
                },
                enableMouseTracking: context !== 'export'
            }
        },
        series: chartData
    };
};

export default radarChart;
