import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import bubbleMapChart from './highcharts/configs/BubbleMapChart';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';

const BubbleMapChartView = highchartsChartViewFactory(bubbleMapChart, 'Map');

BubbleMapChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mapName: PropTypes.string.isRequired,
    mapObject: PropTypes.object.isRequired,
    isInDrillDown: PropTypes.bool,
    customColors: customPropTypes.customColors.isRequired,
    isSampleMetric: PropTypes.bool
};

BubbleMapChartView.defaultProps = {
    isSampleMetric: false
};

export default BubbleMapChartView;
