import {
    adAccountAddToOrRemoveFromGroupError,
    adAccountAddToOrRemoveFromGroupRequest,
    adAccountAddToOrRemoveFromGroupSuccess
} from 'src/actions/adAccounts';
import {
    GROUP_ADD_SUCCESS,
    GROUP_DELETE_SUCCESS,
    GROUP_EDIT_ERROR,
    GROUP_EDIT_REQUEST,
    GROUP_EDIT_SUCCESS,
    GROUP_FORCE_DELETE_SUCCESS
} from 'src/actions/groups';
import {
    BULK_PROFILE_ADD_SUCCESS, CLEANUP_PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP,
    ONBOARDING_BULK_PROFILE_ADD_SUCCESS,
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_ERROR,
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST,
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS,
    PROFILE_DELETE_SUCCESS
} from 'src/actions/profiles';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import {
    createAsyncStatesReducerForAction,
    createAsyncStatesReducerForActionsWithErrorAndSuccessStates
} from 'src/reducers/utils';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';

function allIds(state = [], action) {
    const { payload, type } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            return Object.keys(payload.groups);
        }
        case GROUP_ADD_SUCCESS: {
            return [...state, payload.group.id];
        }
        case GROUP_DELETE_SUCCESS:
        case GROUP_FORCE_DELETE_SUCCESS: {
            const { groupIds } = payload;
            return _difference(state, groupIds);
        }
        default:
            return state;
    }
}

function updateGroupOnProfileAddSuccessOrProfileAddToGroupSuccess(group, profileIds) {
    return Object.assign({}, group, {
        profileIds: [...group.profileIds, ...profileIds]
    });
}

function updateGroupOnAdAccountAddToOrRemoveFromGroupSuccess(group, adAccountIds) {
    return Object.assign({}, group, {
        adAccountIds: [...group.adAccountIds, ...adAccountIds]
    });
}

function updateGroupOnProfileDeleteOrRemoveFromGroup(group, profileIds) {
    const profileIdsExistingInCurrentGroup = profileIds.filter((id) => group.profileIds.indexOf(id) > -1);
    return Object.assign({}, group, {
        profileIds: _difference(group.profileIds, profileIdsExistingInCurrentGroup)
    });
}

function updateGroupOnAdAccountDeleteOrRemoveFromGroup(group, adAccountIds) {
    const adAccountIdsExistingInCurrentGroup = adAccountIds.filter((id) => group.adAccountIds.indexOf(id) > -1);
    return Object.assign({}, group, {
        adAccountIds: _difference(group.adAccountIds, adAccountIdsExistingInCurrentGroup)
    });
}

function byId(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            return Object.assign({}, state, payload.groups);
        }
        case GROUP_ADD_SUCCESS: {
            const { group } = payload;
            return Object.assign({}, state, { [group.id]: group });
        }
        case GROUP_EDIT_SUCCESS: {
            const { group } = payload;
            return Object.assign({}, state, {
                [group.id]: Object.assign({}, state[group.id], group)
            });
        }
        case BULK_PROFILE_ADD_SUCCESS:
        case ONBOARDING_BULK_PROFILE_ADD_SUCCESS: {
            const { profiles } = payload;
            return Object.assign({}, state, {
                0: updateGroupOnProfileAddSuccessOrProfileAddToGroupSuccess(state['0'], Object.keys(profiles))
            });
        }
        case PROFILE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            const updatedGroups = {};
            Object.keys(state).forEach((groupId) => {
                updatedGroups[groupId] = updateGroupOnProfileDeleteOrRemoveFromGroup(state[groupId], profileIds);
            });
            return Object.assign({}, state, updatedGroups);
        }

        case adAccountAddToOrRemoveFromGroupSuccess.type: {
            const updatedGroups = {};
            const { adAccountAddOrRemoveResults } = payload;
            adAccountAddOrRemoveResults.forEach((result) => {
                const { accountId, successAdded, successRemoved } = result;
                successAdded.forEach((groupId) => {
                    const groupFromStateOrUpdatedAlreadyUpdated = _get(updatedGroups, groupId, state[groupId]);
                    updatedGroups[groupId] = updateGroupOnAdAccountAddToOrRemoveFromGroupSuccess(groupFromStateOrUpdatedAlreadyUpdated, [accountId]);
                });
                successRemoved.forEach((groupId) => {
                    const groupFromStateOrUpdatedAlreadyUpdated = _get(updatedGroups, groupId, state[groupId]);
                    updatedGroups[groupId] = updateGroupOnAdAccountDeleteOrRemoveFromGroup(groupFromStateOrUpdatedAlreadyUpdated, [accountId]);
                });
            });
            return Object.assign({}, state, updatedGroups);
        }

        case PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS: {
            const updatedGroups = {};
            const { profileAddOrRemoveResults } = payload;
            profileAddOrRemoveResults.forEach((result) => {
                const { accountId, successAdded, successRemoved } = result;
                successAdded.forEach((groupId) => {
                    const groupFromStateOrUpdatedAlreadyUpdated = _get(updatedGroups, groupId, state[groupId]);
                    updatedGroups[groupId] = updateGroupOnProfileAddSuccessOrProfileAddToGroupSuccess(groupFromStateOrUpdatedAlreadyUpdated, [accountId]);
                });
                successRemoved.forEach((groupId) => {
                    const groupFromStateOrUpdatedAlreadyUpdated = _get(updatedGroups, groupId, state[groupId]);
                    updatedGroups[groupId] = updateGroupOnProfileDeleteOrRemoveFromGroup(groupFromStateOrUpdatedAlreadyUpdated, [accountId]);
                });
            });
            return Object.assign({}, state, updatedGroups);
        }
        case GROUP_DELETE_SUCCESS:
        case GROUP_FORCE_DELETE_SUCCESS: {
            const { groupIds } = payload;
            return _omit(state, groupIds);
        }
        default:
            return state;
    }
}

const asyncStatesByAction = combineReducers({
    edit: createAsyncStatesReducerForAction(
        (payload) => payload.id,
        [GROUP_EDIT_REQUEST],
        [GROUP_EDIT_SUCCESS, GROUP_EDIT_ERROR]
    ),
    addRemoveGroups: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST, adAccountAddToOrRemoveFromGroupRequest.type],
        [PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS, adAccountAddToOrRemoveFromGroupSuccess.type],
        [PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_ERROR, adAccountAddToOrRemoveFromGroupError.type],
        [CLEANUP_PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP]
    ),
});

export const groups = combineReducers({
    allIds,
    byId,
    asyncStatesByAction
});
