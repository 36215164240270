import React from 'react';
import TopContentCard from 'src/components/chartViews/topContent/TopContentCard';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/chartViews/topContent/topContentSlider.scss';
import PropTypes from 'prop-types';
import Carousel from 'src/components/Carousel';

const getDimensions = (width) => {
    const breakpoints = [
        { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 1000, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 1500, settings: { slidesToShow: 3, slidesToScroll: 3 } },
        { breakpoint: 2000, settings: { slidesToShow: 4, slidesToScroll: 4 } },
    ];

    for (let i = 0; i < breakpoints.length; i++) {
        if (width < breakpoints[i].breakpoint) {
            return breakpoints[i].settings;
        }
    }
    return { slidesToShow: 5, slidesToScroll: 5 };
};

const TopContentSlider = (props) => {
    const { results, numberFormat, width } = props;
    const { slidesToShow, slidesToScroll } = getDimensions(width);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.carousel}>
                    <Carousel
                      lazyLoad
                      slidesToShow={slidesToShow}
                      slidesToScroll={slidesToScroll}
                    >
                        {
                            results.map((result) => (
                                <div key={`topContent-${result.internalId}`}>
                                    <div className={styles.card}>
                                        <TopContentCard
                                          pageName={result.pageName}
                                          content={result.content}
                                          profilePicture={result.profilePicture}
                                          thumbnail={result.thumbnail}
                                          link={result.link}
                                          kpis={result.kpis}
                                          platformType={result.platformType}
                                          data={result.data}
                                          numberFormat={numberFormat}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

TopContentSlider.propTypes = {
    results: PropTypes.arrayOf(customPropTypes.data).isRequired,
    numberFormat: customPropTypes.numberFormat.isRequired,
    width: PropTypes.number.isRequired
};

export default TopContentSlider;
