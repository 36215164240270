import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/filter.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';

const AbsoluteLimitParameterSummary = (props) => {
    const { limit } = props;

    return (
        <div className={styles.filterItemWrapper}>
            <IcomoonIcon icon="list" className={styles.icon} />
            <div className={styles.textWrapper}>
                Limited to {limit} rows
            </div>
        </div>
    );
};

AbsoluteLimitParameterSummary.propTypes = {
    limit: PropTypes.number.isRequired
};

export default AbsoluteLimitParameterSummary;
