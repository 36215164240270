/**
 * @file utils.js
 * @description DOM utility functions
 */

// Simple way to generate a unique id
export const uniqId = () => `uid-${Date.now()}`;

// Camelcase a dashed string, ie do-thing => doThing
export const camelize = (str) => str.replace(/-(\w)/g, (s, letter) => letter.toUpperCase());

/* eslint-disable no-param-reassign, guard-for-in, no-restricted-syntax */
// Set multiple css properties on an element `el` by
// passing in a `style` object that defines what properties
// to set and their value
export const css = (el, styles) => {
    for (const property in styles) {
        el.style[property] = styles[property];
    }
};

// Get the current style property value for the given element
export function getStyle(el, styleProp) {
    if (el.currentStyle) {
        return el.currentStyle[camelize(styleProp)];
    }
    if (document.defaultView && document.defaultView.getComputedStyle) {
        return document.defaultView
            .getComputedStyle(el, null)
            .getPropertyValue(styleProp);
    }
    return el.style[camelize(styleProp)];
}

export function getOverflow(el) {
    return [
        el.clientWidth < el.scrollWidth,
        el.clientHeight < el.scrollHeight
    ];
}

export const hasOverflow = (el) => getOverflow(el).some((v) => v === true);

// Determine the font-size to set on the element `el` that will
// allow the first child of that element to fill the maximum height
// and width without causing overflow
export const getFillSize = (el, minFontSize, maxFontSize, widthOnly = false, factor = 2) => {
    // Make an initial guess at font-size that fits width
    let fontSize = Math.min(
        Math.max(Math.min(Number(el.offsetWidth) / (factor * 10), maxFontSize), minFontSize)
    );

    const step = 1;
    let complete;

    while (!complete) {
        el.style.fontSize = `${fontSize}px`;
        const [overflowWidth, overflowHeight] = getOverflow(el);

        if (!widthOnly && (overflowHeight || overflowWidth)) {
            if (fontSize <= minFontSize) {
                fontSize = minFontSize;
                complete = true;
            } else {
                fontSize -= step;
                complete = true;
            }
        } else if (widthOnly && overflowWidth) {
            fontSize -= step;
            complete = true;
        } else if (fontSize >= maxFontSize) {
            fontSize = maxFontSize;
            complete = true;
        } else if (!complete) {
            fontSize += step;
        }
    }
    return fontSize;
};
