import moment from 'moment';

const getDayOfWeek = (weekDefinition) => {
    let dow = 1;
    if (weekDefinition) {
        if (weekDefinition === 'SuSa') {
            dow = 0;
        }
    }
    return dow;
};

export const updateWeekDefinition = (weekDefinition) => {
    moment.updateLocale('en', {
        week: {
            dow: getDayOfWeek(weekDefinition) // Monday is the first day of the week.
        }
    });
};
