import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';
import twoDimChart from './highcharts/configs/TwoDimChart';

const TwoDimChartView = highchartsChartViewFactory(twoDimChart);

TwoDimChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    defaultSeriesType: PropTypes.oneOf(['line', 'column', 'area', 'bar', 'waterfall']).isRequired,
    context: PropTypes.string.isRequired,
    stacked: PropTypes.bool,
    selectedDate: customPropTypes.selectedDate,
    percent: PropTypes.bool,
    dateFormat: PropTypes.string.isRequired,
    customColors: customPropTypes.customColors.isRequired,
    favorites: PropTypes.objectOf(PropTypes.bool),
    weekDefinition: PropTypes.oneOf(['SuSa', 'MoSu']).isRequired,
    metricParameters: PropTypes.object,
    detectIsolatedPoint: PropTypes.bool,
    isSampleMetric: PropTypes.bool
};

TwoDimChartView.defaultProps = {
    stacked: false,
    percentage: false,
    detectIsolatedPoint: false,
    isSampleMetric: false
};

export default TwoDimChartView;
