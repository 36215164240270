import PropTypes from 'prop-types';
import withLazyLoad from 'src/components/lazyLoad/withLazyLoad';
import withFlexDimensionProvider from 'src/components/withFlexDimensionProvider';
import ChartView from './ChartView';

const ChartViewWithFlexDimensionProvider = withFlexDimensionProvider(withLazyLoad(ChartView), true, true, 250);

ChartViewWithFlexDimensionProvider.propTypes = {
    lazyLoading: PropTypes.bool
};

ChartViewWithFlexDimensionProvider.defaultProps = {
    lazyLoading: false
};

export default ChartViewWithFlexDimensionProvider;
