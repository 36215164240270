import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/filter.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import AbsoluteSelectedDateLabel from 'src/components/dateSelector/AbsoluteSelectedDateLabel';
import FilterItem from './FilterItem';

const DateFilterSummary = (props) => {
    const {
        state,
        input,
        hasCustomBinding
    } = props;

    const date = {
        from: input.from,
        to: input.to,
        interval: input.interval,
        timezone: input.timezone
    };

    return (
        <FilterItem
          state={state}
          hasCustomBinding={hasCustomBinding}
          icon={<IcomoonIcon icon="date-selector" className={styles.icon} />}
          content={<AbsoluteSelectedDateLabel selectedDate={date} />}
        />
    );
};

DateFilterSummary.propTypes = {
    state: PropTypes.oneOf(['fullyApplied', 'partiallyApplied', 'notApplied']).isRequired,
    input: customPropTypes.dateFilter.isRequired,
    hasCustomBinding: PropTypes.bool
};

DateFilterSummary.defaultProps = {
    hasCustomBinding: false
};

export default DateFilterSummary;
