import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import { getStartOfWeek } from './utils';
import { makeResolveTooltipFormatterByMetricId } from './TooltipFormatter';

const isZerosOnly = (data, series) => {
    for (let i = 0, iLen = series.length; i < iLen; i += 1) {
        const serie = series[i];
        for (let j = 0, jLen = data.hcChartData[serie.name].length; j < jLen; j += 1) {
            if (data.hcChartData[serie.name][j].z > 0) {
                return false;
            }
        }
    }
    return true;
};

const makeDefaultFormatter = (data) => function formatter() {
    const labels = [];
    const additionalLabels = _get(data, 'additionalLabels', []);
    additionalLabels.forEach((prop) => {
        if (typeof this.point[prop] !== 'undefined') {
            labels.push(`${this.point[prop]} ${additionalLabels[prop]}`);
        }
    });

    // if no labels were specified, use the z value as a fallback
    if (labels.length === 0) {
        labels.push(this.point.z);
    }
    return `<b>${data.categoriesY[this.point.y]}, ${data.categoriesX[this.point.x]}</b><br />${labels.join('<br />')}`;
};

const bubbleChart = (props, Highcharts, formatNumber) => {
    const {
        data,
        width,
        height,
        weekDefinition,
        numberFormat,
        metricId
    } = props;
    const series = [];

    Object.keys(data.hcChartData).forEach((attr) => {
        series.push({
            name: attr,
            data: data.hcChartData[attr]
        });
    });
    const zerosOnly = isZerosOnly(data, series);
    let formatter = makeResolveTooltipFormatterByMetricId(formatNumber, numberFormat, metricId, data);
    if (!formatter) {
        formatter = makeDefaultFormatter(data);
    }
    return {
        chart: {
            defaultSeriesType: 'bubble',
            animation: false,
            zoomType: 'xy',
            width,
            height
        },
        xAxis: {
            categories: data.categoriesX,
            gridLineWidth: 0,
            labels: {
                step: 2
            },
            startOfWeek: getStartOfWeek(weekDefinition)
        },
        yAxis: [{
            categories: data.categoriesY,
            labels: {
                formatter() {
                    // This check seems to be very very very old.
                    // For whatever reasons the yaxis gets numbers added. eg -1, Sun, Mon, Tue.
                    // We don't render them in that case
                    if (_isNumber(this.value)) {
                        return '';
                    }
                    return this.value;
                }
            },
            title: null,
            gridLineWidth: 0,
            lineWidth: 1,
            endOnTick: false,
            startOfWeek: getStartOfWeek(weekDefinition)
        }],
        plotOptions: {
            bubble: {
                maxSize: zerosOnly ? 0 : '6%'
            }
        },
        tooltip: {
            formatter
        },
        legend: {
            enabled: (series.length > 1)
        },
        series
    };
};

export default bubbleChart;
