import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _has from 'lodash/has';
import classnames from 'classnames';
import ClientSideTable from 'src/components/chartViews/fixedDataTable/ClientSideTable';
import ExportTable from 'src/components/chartViews/fixedDataTable/ExportTable';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import PropTypes from 'prop-types';
import ServerSideTable from 'src/components/chartViews/fixedDataTable/ServerSideTable';
import styles from 'src/stylesheets/tableView.scss';
import memoizeOne from 'memoize-one';
import FullyAppliedFiltersContext from 'src/components/context/FullyAppliedFiltersContext';

const getColumnValidationErrorOrFalse = (columns, metricName) => {
    for (let i = 0, iLen = columns.length; i < iLen; i++) {
        const column = columns[i];
        if (!_has(column, 'id')) {
            return 'There is a column with no id set';
        }
        if (!_has(column, 'title')) {
            return `Column with id "${column.id}" has no "title" set for table "${metricName}"`;
        }
    }
    return false;
};

const getMetricConfigValidationErrorOrFalse = (metricConfig, metricName) => {
    if (_has(metricConfig, 'metaData')) {
        const { metaData } = metricConfig;
        if (_has(metaData, 'columns') && metaData.columns.length > 0) {
            const { columns } = metaData;
            const validationError = getColumnValidationErrorOrFalse(columns, metricName);
            if (validationError) {
                return validationError;
            }
        } else {
            return `No column definitions given for table "${metricName}"`;
        }
        if (!_has(metaData, 'sortBy')) {
            return `No default "sortBy" parameter given for table "${metricName}"`;
        }

        if (!_has(metaData, 'sortDir')) {
            return `No default "sortDir" parameter given for table "${metricName}"`;
        }

        if (!_has(metaData, 'paginate')) {
            return `No default "paginate" parameter given for table "${metricName}"`;
        }

        if (_has(metaData, 'summaryLine') && metaData.summaryLine.enabled && _has(metaData, 'paginate') && metaData.paginate) {
            return `"Pagination" and "summaryLine" parameters can't be both enabled for table "${metricName}"`;
        }
    } else {
        return `There is no meta data with further table definitions for table "${metricName}"`;
    }
    return false;
};

const getTableConfigAndError = (metricConfig, metric, settings) => {
    const error = getMetricConfigValidationErrorOrFalse(metricConfig, metric.name);
    let tableConfig = null;
    if (!error) {
        const { metaData } = metricConfig;
        const { columns } = metaData;
        const defaultHiddenColumnIds = [];
        columns.forEach((column) => {
            const isHidden = column.hidden || false;
            if (isHidden) {
                defaultHiddenColumnIds.push(column.id);
            }
        });

        tableConfig = {
            sortBy: _get(settings, 'settingAdditional.sortBy', metaData.sortBy),
            sortDir: _get(settings, 'settingAdditional.sortDir', metaData.sortDir),
            paginate: metaData.paginate,
            summaryLine: metaData.summaryLine,
            columns,
            hideColumns: defaultHiddenColumnIds
        };
    }
    return {
        error,
        tableConfig,
    };
};

class TableView extends Component {
    constructor(props) {
        super(props);
        this.memoizedGetTableConfigAndError = memoizeOne(getTableConfigAndError);
    }

    // eslint-disable-next-line class-methods-use-this
    handleResize() {
        return false;
    }

    render() {
        const {
            width,
            height,
            data,
            className,
            settings,
            selectedProfilesOrGroups,
            metric,
            metricConfig,
            selectedDate,
            isExport,
            isSampleMetric
        } = this.props;
        const { error, tableConfig } = this.memoizedGetTableConfigAndError(metricConfig, metric, settings);
        if (error) {
            return <div>{error}</div>;
        }

        const { results, total, requestInfo } = data;
        const totalResultsCount = parseInt(total, 10);
        const fullyAppliedFilters = _get(requestInfo, ['filters', 'fullyApplied']);
        const sharedTableProps = {
            width,
            height,
            headerHeight: 30,
            ref: (c) => { this.table = c; },
            tableConfig,
            results,
            summaryLine: _get(data, 'summaryLine', null),
            settings,
            paginate: _get(tableConfig, 'paginate', false)
        };

        let table;

        if (isExport) {
            table = (
                <ExportTable
                  {...sharedTableProps}
                />
            );
        } else if (isSampleMetric) {
            table = (
                <ClientSideTable {...sharedTableProps} />
            );
        } else {
            table = (
                <MetricRequestContext.Consumer>
                    {
                        (serverRequest) => (
                            <ServerSideTable
                              {...sharedTableProps}
                              selectedProfilesOrGroups={selectedProfilesOrGroups}
                              metric={metric}
                              selectedDate={selectedDate}
                              totalResultsCount={totalResultsCount}
                              serverRequest={serverRequest}
                            />
                        )
                    }
                </MetricRequestContext.Consumer>
            );
        }

        return (
            <div className={className}>
                <div className={classnames(styles.tableWrapper, { [styles.export]: isExport })}>
                    <FullyAppliedFiltersContext.Provider value={fullyAppliedFilters}>
                        {table}
                    </FullyAppliedFiltersContext.Provider>
                </div>
            </div>
        );
    }
}

TableView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    context: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
    selectedDate: customPropTypes.selectedDate,
    selectedProfilesOrGroups: customPropTypes.profileFilter,
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    settings: customPropTypes.dashboardMetricSettings,
    isExport: PropTypes.bool,
    isSampleMetric: PropTypes.bool
};

TableView.defaultProps = {
    isExport: false,
    isSampleMetric: false
};

export default TableView;
