import _get from 'lodash/get';

export const getMapFromMetric = (metric) => _get(metric, 'config.metaData.map', 'world');

// Map chart utils
export function getCountriesFromMapObject(mapObject) {
    const { infoData } = mapObject;
    if (_get(infoData, 'type', false) === 'overview') {
        const countryCodes = [];
        const countries = mapObject.mapData.features;
        for (let i = 0, iLen = countries.length; i < iLen; i++) {
            if (countries[i].properties['iso-a2'] !== null) {
                countryCodes.push(countries[i].properties['iso-a2'].toLowerCase());
            }
        }
        return countryCodes;
    }
    return [mapObject.countryCode];
}

export const highchartsCachedMaps = {};
