import { ACCOUNT_OPTIONS_SAVE_SUCCESS, ADD_FIRST_APP_STARTED_TIME_SUCCESS, USER_LOGGED_IN } from 'src/actions/loggedInUser';
import _difference from 'lodash/difference';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import { CREATE_SPACE_SUCCESS, DELETE_SPACE_SUCCESS } from 'src/actions/spaces';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';

const defaultSetting = {
    userId: '',
    loggedInSpaceId: '',
    userSpaceIds: [],
    isAdmin: false,
    isReadOnly: true,
    isAgent: false,
    firstAppStartedTime: null,
    accountOptions: {}
};

export const loggedInUser = (state = defaultSetting, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { loggedInUser: loggedInUserFromPayload } = payload;
            const {
                accountOptions, loggedInSpaceId, userId, userSpaceIds, isReadOnly, isAdmin, email, isAgent, firstAppStartedTime, intercomHash
            } = loggedInUserFromPayload;
            return {
                userId, loggedInSpaceId, userSpaceIds, isReadOnly, isAdmin, isAgent, email, firstAppStartedTime, accountOptions, intercomHash
            };
        }
        case ADD_FIRST_APP_STARTED_TIME_SUCCESS: {
            const { firstAppStartedTime } = payload;
            return Object.assign({}, state, { firstAppStartedTime });
        }
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case ACCOUNT_OPTIONS_SAVE_SUCCESS:
        case PREPARE_EXPORT_DATA: {
            return Object.assign({}, state, { accountOptions: payload.accountOptions });
        }
        case CREATE_SPACE_SUCCESS: {
            const { space, currentUserId } = payload;
            const { users } = space;
            const user = _filter(users, { id: currentUserId })[0] || [];
            if (_isEmpty(user)) {
                return state;
            }
            const oldSpaceIds = Object.assign({}, state.userSpaceIds);
            const newSpaceIds = _difference(user.spaces.map((currentSpace) => currentSpace.id), oldSpaceIds);
            return Object.assign({}, state, { userSpaceIds: newSpaceIds });
        }
        case DELETE_SPACE_SUCCESS: {
            const { spaceId } = payload;
            const cleanedSpaceIds = state.userSpaceIds.filter((id) => id !== spaceId);
            return Object.assign({}, state, { userSpaceIds: cleanedSpaceIds });
        }
        default:
            return state;
    }
};
