import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import highchartsChartViewFactory from './highcharts/highchartsChartViewFactory';
import mapChart from './highcharts/configs/MapChart';

const MapChartView = highchartsChartViewFactory(mapChart, 'Map');

MapChartView.propTypes = {
    data: customPropTypes.data.isRequired,
    metricConfig: customPropTypes.metricConfig.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mapName: PropTypes.string.isRequired,
    mapObject: PropTypes.object.isRequired,
    isInDrillDown: PropTypes.bool
};

export default MapChartView;
