import { getColumnsToRender } from 'src/components/chartViews/fixedDataTable/utils';
import React from 'react';
import styles from 'src/stylesheets/table/exportTable.scss';
import SummaryLine from 'src/components/chartViews/fixedDataTable/tableEngine/export/SummaryLine';
import TableBodyRows from 'src/components/chartViews/fixedDataTable/tableEngine/export/TableBodyRows';
import TableHeaderRow from 'src/components/chartViews/fixedDataTable/tableEngine/export/TableHeaderRow';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import FixedDataTableWidthHelper from 'src/components/chartViews/fixedDataTable/tableEngine/FixedDataTableWidthHelper';

// we don't need actually exactly 100% in sum. The table engine takes care about fitting spaces.
// Set max to 20% and min to 3 % for a more equal distribution
const calculateColumnWidth = (columns) => {
    const columnsWithAbsoluteWidth = [];
    let totalWidthRatio = 0;
    columns.forEach((column) => {
        const currentWidthRatio = FixedDataTableWidthHelper.getWidthRatioOrDefault(column);
        totalWidthRatio += currentWidthRatio;
    });
    columns.forEach((column) => {
        const currentWidthRatio = FixedDataTableWidthHelper.getWidthRatioOrDefault(column);
        const currentOrMinValue = Math.max(2, ((currentWidthRatio / totalWidthRatio) * 100));
        const currentOrMaxValue = Math.min(90, Math.ceil(currentOrMinValue));
        columnsWithAbsoluteWidth.push(Object.assign({}, column, { absoluteWidth: `${currentOrMaxValue}%` }));
    });
    return columnsWithAbsoluteWidth;
};

const Table = (props) => {
    const {
        columns,
        hideColumns,
        columnsSortBy,
        columnsSortDir,
        results,
        summaryLineResults,
        summaryLineEnabled
    } = props;

    const rowsCount = results.getSize();
    const showSummaryLine = summaryLineEnabled && rowsCount > 0;

    const visibleColumns = getColumnsToRender(columns, hideColumns);
    const columnsWithDimensions = calculateColumnWidth(visibleColumns);
    return (
        <table className={styles.table}>
            <TableHeaderRow
              columns={columnsWithDimensions}
              sortBy={columnsSortBy}
              sortDir={columnsSortDir}
            />
            <tbody>
                <TableBodyRows
                  rowsCount={rowsCount}
                  results={results}
                  columns={visibleColumns}
                  sortBy={columnsSortBy}
                  sortDir={columnsSortDir}
                />
                {
                    showSummaryLine
                    && <SummaryLine summaryLineResults={summaryLineResults} columns={visibleColumns} results={results} />
                }
            </tbody>
        </table>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(customPropTypes.tableCellColumn).isRequired,
    hideColumns: PropTypes.arrayOf(customPropTypes.hideColumn).isRequired,
    columnsSortBy: PropTypes.any,
    columnsSortDir: PropTypes.any,
    results: PropTypes.any,
    summaryLineResults: PropTypes.any,
    summaryLineEnabled: PropTypes.any
};

export default Table;
